// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
global.$ = require("jquery")
require("turbolinks").start()
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('bootstrap/dist/js/bootstrap')
require('bootstrap-select/dist/js/bootstrap-select')
require('bootstrap-select/dist/js/i18n/defaults-sl_SI')
require('jquery.easing/jquery.easing')
global.moment = require('bootstrap-daterangepicker/moment.min')
require('bootstrap-daterangepicker/daterangepicker')
require('bootstrap-datepicker/dist/js/bootstrap-datepicker')
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.sl')
require('datatables.net-bs4/js/dataTables.bootstrap4')
require('bootstrap-pincode-input/js/bootstrap-pincode-input.js')
require('data-confirm-modal/vendor/assets/javascripts/data-confirm-modal')
global.Twine = require('twine/dist/twine')
global.bsCustomInputFile = require('assets/javascripts/bs-custom-file-input')
require('bootstrap-pincode-input/js/bootstrap-pincode-input.js')
global.SignaturePad = require('signature_pad/dist/signature_pad').default
// require('jquery-simplecolorpicker/jquery.simplecolorpicker.js')

// from vendor
require('assets/javascripts/sbadmin2.min')
require('assets/javascripts/jquery-simplecolorpicker/jquery.simplecolorpicker.js')
require('assets/javascripts/qrcode.js')

// from app/javascript/general/index.js
global.ActionCable = require('@rails/actioncable')
require("general")

// styles!!!
require("stylesheets/application")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
