jQuery.fn.preventDoubleSubmission = function() {
  $(this).on('submit',function(e){
    var $form = $(this);

    if ($form.data('submitted') === true) {
      // Previously submitted - don't submit again
      e.preventDefault();
    } else {
      // Mark it so that the next submit can be ignored
      $form.data('submitted', true);
      setTimeout(function() { $form.data('submitted', false) }, 10000);    //unlock it after 10s!
    }
  });

  // Keep chainability
  return this;
};

var tooltips = {
  init: function() {    
    $('[data-toggle="tooltip"]').tooltip({container: '#content-wrapper'});
  }
};

global.ws = {
  subscribe: function(channel, params, onReceived) {
    var consumer = ActionCable.createConsumer();
    params = params || {};
    params.channel = channel;
    console.log(params);
    consumer.subscriptions.create(params, {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log(`${channel} connected`);
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        console.log(`${channel} disconnected`);
      },

      received(data) {
        if(onReceived) {
          onReceived(data);
        }
      }
    });
  }
};

global.onload = {
  init: function() {
    // init datatables
    $('.data-table').DataTable({
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.10.20/i18n/Slovenian.json'
      }
    });
    // init tooltips
    tooltips.init();
    // prevent multiple form submissions
    $('form').preventDoubleSubmission();
    // nice confirm popups
    dataConfirmModal.setDefaults({
      title: 'Potrditev',
      commit: 'Potrdi',
      commitClass: 'btn btn-sm btn-success px-4',
      cancel: 'Prekliči',
      cancelClass: 'btn btn-sm btn-danger px-4'
    });

    // init custom select pickers
    $('.select-picker').selectpicker();
    $('.select-picker').on('shown.bs.select', function() {
      var $input = $(this).parent().find('.bs-searchbox input');
      setTimeout(function() { $input.focus(); }, 100);
    });
    $('.bootstrap-select .bootstrap-select').remove();  // on back button we get duplicates (removes such)
    $('.select-picker').selectpicker('refresh');        // rerenders options (on back button are not render by default because of duplicate)
  }
};

var overrides = {
  spinnerAjax: function($holder, url, settings) {
    $holder.find('.ball-spinner').show();
    settings = settings || {};
    settings.complete = function() { $holder.find('.ball-spinner').hide(); };
    $.ajax(url, settings);
  }
};

global.matches = {
  data: {},
  init: function(url) {
    matches.data.url = url;
    $('select.competition').change(matches.search);
    $('select.assistant_referee').change(matches.search);
    $('[data-provide="datepicker"]').datepicker().on("changeDate", matches.search);
    setTimeout(matches.search, 500);
  },
  search: function() {
    var comp_id = $('select.competition').val();
    var assistant_referee_id = $('select.assistant_referee').val();
    var $tableHolderDiv = $('.table-holder');
    var $spinnerDiv = $('.spinner-holder');

    $tableHolderDiv.hide();
    if(comp_id !== '') {
      overrides.spinnerAjax($spinnerDiv, matches.data.url, {
        method: 'GET',
        data: {
          competition_id: comp_id,
          assistant_referee_id: assistant_referee_id,
          start_date: $('.start-date').val(),
          end_date: $('.end-date').val()
        },
        success: function(data) {
          $('.start-date').val(data.start_date);
          $('.end-date').val(data.end_date);
          $tableHolderDiv.html(data.html);
          $tableHolderDiv.show();
          tooltips.init();
        },
        error: function(e) {
          console.log('error', e);
        }
      });
    }
  }
};

global.statistics = {
  data: {},
  init: function(url) {
    statistics.data.url = url;
    $('[data-provide="datepicker"]').datepicker().on("changeDate", statistics.search);
    setTimeout(statistics.search, 500);
  },
  search: function() {
    var $tableHolderDiv = $('.table-holder');
    var $spinnerDiv = $('.spinner-holder');

    $tableHolderDiv.hide();
    overrides.spinnerAjax($spinnerDiv, statistics.data.url, {
      method: 'GET',
      data: {
        from_date: $('.from-date').val(),
        to_date: $('.to-date').val()
      },
      success: function(data) {
        $('.from-date').val(data.from_date);
        $('.to-date').val(data.to_date);
        $tableHolderDiv.html(data.html);
        $tableHolderDiv.show();
      },
      error: function(e) {
        console.log('error', e);
      }
    });
  }
};

var ajaxLoad = function(url, success, complete) {
  $.ajax(url, {
    method: 'GET',
    success: success,
    error: function(e) {
      console.log('load error for ' + url, e);
    },
    complete: complete
  });
};

global.preMatch = {
  data: {
    home: {
      defaultColor: { bg: '#007bff', text: '#ffffff' }
    },
    away: {
      defaultColor: { bg: '#dc3545', text: '#ffffff' }
    },
    resetBodyText: {
      coach: "<b>Ste prepričani, da želite ponovno vnesti celotno klop?</b><br><br>Do sedaj vnešeni podatki o klopi bodo ponastavljeni, potreben bo tudi ponoven podpis trenerja.",
      scorekeeper: "<b>Ste prepričani, da želite ponovno vnesti zapisnikarja?</b><br><br>Do sedaj vnešeni podatki o njem bodo ponastavljeni, potreben bo tudi ponoven podpis.",
      scorekeeper_assistant: "<b>Ste prepričani, da želite ponovno vnesti pom. zapisnikarja?</b><br><br>Do sedaj vnešeni podatki o njem bodo ponastavljeni, potreben bo tudi ponoven podpis.",
      timekeeper: "<b>Ste prepričani, da želite ponovno vnesti časomerilca?</b><br><br>Do sedaj vnešeni podatki o njem bodo ponastavljeni, potreben bo tudi ponoven podpis.",
      timekeeper_assistant: "<b>Ste prepričani, da želite ponovno vnesti merilca napada?</b><br><br>Do sedaj vnešeni podatki o njem bodo ponastavljeni, potreben bo tudi ponoven podpis.",
      referee1: "<b>Ste prepričani, da želite ponovno vnesti 1. sodnika?</b><br><br>Do sedaj vnešeni podatki o njem bodo ponastavljeni, potreben bo tudi ponoven podpis.",
      referee2: "<b>Ste prepričani, da želite ponovno vnesti 2. sodnika?</b><br><br>Do sedaj vnešeni podatki o njem bodo ponastavljeni, potreben bo tudi ponoven podpis.",
      referee3: "<b>Ste prepričani, da želite ponovno vnesti 3. sodnika?</b><br><br>Do sedaj vnešeni podatki o njem bodo ponastavljeni, potreben bo tudi ponoven podpis.",
      commissioner: "<b>Ste prepričani, da želite ponovno vnesti teh. komisarja?</b><br><br>Do sedaj vnešeni podatki o njem bodo ponastavljeni, potreben bo tudi ponoven podpis.",
      statistician1: "<b>Ste prepričani, da želite ponovno vnesti statistika 1?</b><br><br>Do sedaj vnešeni podatki o njem bodo ponastavljeni, potreben bo tudi ponoven podpis.",
      statistician2: "<b>Ste prepričani, da želite ponovno vnesti statistika 2?</b><br><br>Do sedaj vnešeni podatki o njem bodo ponastavljeni, potreben bo tudi ponoven podpis."
    }
  },
  init: function(urls, settings, competitionId, isCustom, matchId) {
    preMatch.data.urls = urls;
    preMatch.data.isCustom = isCustom;
    preMatch.data.competition_id = competitionId;
    preMatch.data.match_id = matchId;
    context = {
      loaded: false,
      home: {
        color: preMatch.data.home.defaultColor,
        coach: {},
        assistant: {},
        representative: {},
        players: []
      },
      away: {
        color: preMatch.data.away.defaultColor,
        coach: {},
        assistant: {},
        representative: {},
        players: []
      },
      numberOfPlayerForField(side, fn) {
        var n = 0;
        for(var p of (this[side].players || [])) {
          if(p[fn]) {
            n++;
          }
        }
        return n;
      },
      numberOfPlayers: function(side) {
        return this.numberOfPlayerForField(side, 'in_lineup');
      },
      numberOfPlayersInStarting5: function(side) {
        return this.numberOfPlayerForField(side, 'is_starting');
      },
      numberOfPlayersInA: function(side) {
        return this.numberOfPlayerForField(side, 'in_a');
      },
      numberOfPlayersInB: function(side) {
        return this.numberOfPlayerForField(side, 'in_b');
      },
      numberOfCaptains: function(side) {
        return this.numberOfPlayerForField(side, 'is_captain');
      },
      hasOfficials: function() {
        return !!context.referees;
      },
      getOfficialFromApi: function(side, role) {
        if(this[side][role]) {
          this[side][role].searching = true;
          refreshContext();
        }
        // preMatch.loadReferees(role);
      },
      isOfficial: function(type) {
        return this.hasOfficials() && !!this.referees[type]; 
      },
      officialLabel: function(type) {
        if(type === 'referee1') {
          return "1. sodnik: ";
        } else if(type === 'referee2') {
          return "2. sodnik: ";
        } else if(type === 'referee3') {
          return "3. sodnik: ";
        } else if(type === 'commissioner') {
          return "Teh. komisar: ";
        } else {
          return "";
        }
      },
      official: function(type) {
        return this.isOfficial(type) && this.referees[type];
      },
      officialNameApi: function(type) {
        var off = this.official(type);
        if(off && off.fms_id) {
          return this.officialName(type);
        } else {
          return "Uradne osebe ni bilo mogoče pridobiti avtomatsko. Vnesite jo ročno.";
        }
      },
      officialName: function(type) {
        var off = this.official(type);
        if(off) {
          var s = off.first_name + " " + off.last_name;
          if(off.city) s += " (" + off.city + ")";
          return s;
        } else {
          return "";
        }
      },
      json: function() {
        return JSON.stringify(this);
      },
      playerPhotos: function(side) {
        var photos = [];
        for(let p of this[side].players) {
          if(p && p.photo_url) photos.push(p.photo_url);
        }
        return photos;
      },
      hasPlayerPhoto: function(side, index) {
        var p = this.player(side, index);
        return p && p.photo_url;
      },
      showPlayerPhoto: function(side, index) {
        var p = this.player(side, index);
        alerts.info(this.playerName(side, index), "<p class='text-center'><img style='max-width: 100%;' src='"+p.photo_url+"' /></p>");
      },
      hasPlayers: function(side) {
        return !!this[side].players;
      },
      isPlayer: function(side, index) {
        return this.hasPlayers(side) && this[side].players.length > index;
      },
      player: function(side, index) {
        return this.isPlayer(side, index) && this[side].players[index];
      },
      playerLicenseNumber: function(side, index) {
        var p = this.player(side, index);
        return p ? p.license_number : '';
      },
      playerNumber: function(side, index) {
        var p = this.player(side, index);
        return p ? p.number : '';
      },
      playerTeam: function(side, index) {
        var p = this.player(side, index);
        return p ? p.team : '';
      },
      playerName: function(side, index) {
        var p = this.player(side, index);
        return p ? p.last_name.toUpperCase() + " " + p.first_name : '';
      },
      playerFirstName: function(side, index) {
        var p = this.player(side, index);
        return p ? p.first_name : '';
      },
      playerLastName: function(side, index) {
        var p = this.player(side, index);
        return p ? p.last_name : '';
      },
      isPlayerInLineup: function(side, index) {
        var p = this.player(side, index);
        return p && p.in_lineup;
      },
      isPlayerInA: function(side, index) {
        var p = this.player(side, index);
        return p && p.in_a;
      },
      isPlayerInB: function(side, index) {
        var p = this.player(side, index);
        return p && p.in_b;
      },
      isPlayerStarting: function(side, index) {
        var p = this.player(side, index);
        return p && p.is_starting;
      },
      isPlayerCaptain: function(side, index) {
        var p = this.player(side, index);
        return p && p.is_captain && index+"";
      },
      onPlayerChange: function(el, event, side, index) {
        var p = this.player(side, index);
        if(p) {
          p.number = el.value;
          refreshContext();
        }
      },
      onPlayerInAChange: function(el, event, side, index) {
        var p = this.player(side, index);
        if(p) {
          p.in_a = el.checked;
          p.in_lineup = el.checked;
          refreshContext();
        }
      },
      onPlayerInBChange: function(el, event, side, index) {
        var p = this.player(side, index);
        if(p) {
          p.in_b = el.checked;
          p.in_lineup = el.checked;
          refreshContext();
        }
      },
      onPlayerInLineupChange: function(el, event, side, index) {
        var p = this.player(side, index);
        if(p) {
          p.in_lineup = el.checked;
          refreshContext();
        }
      },
      onPlayerIsStartingChange: function(el, event, side, index) {
        var p = this.player(side, index);
        if(p) {
          p.is_starting = el.checked;
          refreshContext();
        }
      },
      onPlayerIsCaptainChange: function(el, event, side, index) {
        var p = this.player(side, index);
        if(p) {
          for(let pl of this[side].players) {
            pl.is_captain = false;
          }
          p.is_captain = el.checked;
          if(p.is_captain && this.isPersonMissing(side, 'coach')) {
            this.setCaptainAsCoach(side);
          }
          refreshContext();
        }
      },
      resetJerseyNumbers: function(side) {
        var con = this;
        var callback = function() {
          for(var p of con[side].players) {
            p.number = "";
          }
          refreshContext();
        };
        alerts.confirmation({ title: "Potrditev", body: "<p>Ali ste prepričani, da hočete ponastaviti številko dresa pri vseh igralcih?</p>", yesCallback: callback });
      },
      checkLicenseNumber: function(side, role) {
        var lic = this[side][role].license_number;
        if(side == 'home' || side == 'away') {
          preMatch.validateCoach(side, role, lic);
        } else if(side == 'assistant_referees') {
          preMatch.validateAssistantReferee(side, role, lic);
        } else if(side == 'statisticians') {
          preMatch.validateStatistician(side, role, lic);
        }
      },
      licenseNumberText: function(side, role) {
        var lic = this[side][role].license_number;
        if(lic) {
          return "("+lic+")";
        } else {
          return "";
        }
      },
      canConfirmPerson: function(side, role) {
        return this[side][role].status == 'missing' || this.hasPersonName(side, role);
      },
      confirmPerson: function(side, role) {
        if(this.canConfirmPerson(side, role)) {        
          var con = this;
          var callback = function() {        
            setContext(side + "." + role + ".confirmed", "confirmed");
            con.save(function() {
              if(side != 'referees' && !con.isPersonMissing(side, role)) {
                con.showQrCode(side, role); 
              } 
            });
          };
          alerts.confirmation({ title: "Potrditev", body: "<p>Ali ste prepričani o potrditvi?</p>", yesCallback: callback });
        }
      },
      canConfirmBench: function(side) {
        for(role of ['coach', 'assistant', 'representative']) {
          if(!this.canConfirmPerson(side, role)) return false;
        }
        return true;
      },
      confirmBenchAndSignCoach: function(side) {
        if(this.canConfirmBench(side)) {
          var con = this;
          var callback = function() {        
            for(role of ["coach", "assistant", "representative"]) {
              setContext(side + "." + role + ".confirmed", "confirmed");
            }
            con.save( function() {
              var role = 'coach';
              if(!con.isPersonMissing(side, role)) {
                con.showQrCode(side, role); 
              } 
            });
          };
          alerts.confirmation({ title: "Potrditev", body: "<p>Ali ste prepričani o potrditvi?</p><p><small>Po potrditvi klopi ne bo več možno spreminjati.</small></p>", yesCallback: callback }); 
 
        }
      },
      isPersonConfirmed: function(side, role) {
        return this[side][role] && this[side][role].confirmed == 'confirmed';
      },
      hasPersonLicense: function(side, role) {
        return this[side][role] && this[side][role].status === "with_license";
      },
      isPersonWithoutLicense: function(side, role) {
        return this[side][role] && this[side][role].status === "without_license";
      },
      isPersonMissing: function(side, role) {
        return this[side][role] && this[side][role].status === "missing";
      },
      isPersonFromApi: function(side, role) {
        return this[side][role] && this[side][role].status === "API";
      },
      isPersonFromApiSearching: function(side, role) {
        return this[side][role] && this[side][role].status === "API" && this[side][role].searching;
      },
      isPersonFromManualCustom: function(side, role) {
        return this[side][role] && this[side][role].status === "MANUAL_CUSTOM";
      },
      syncField: function(field, value) {
        setContext(field, value);
      },
      hasPersonName: function(side, role) {
        return this[side][role] && this[side][role].first_name && this[side][role].last_name;
      },
      hasPersonCity: function(side, role) {
        return this[side][role] && this[side][role].city;
      },
      personName: function(side, role) {
        if(this.hasPersonName(side, role)) {
          return this[side][role].first_name + " " + this[side][role].last_name;
        } else {
          return "";
        }
      },
      onPersonMissingRadioClick: function(side, role) {
        setTimeout(function() {
          if(role == 'coach') {
            this.setCaptainAsCoach(side);  
          }
        }.bind(this), 100);
      },
      resetPersonAction: function(side, role) {
          this[side][role] = { license_number: "", first_name: "", last_name: "", city: "" };
      },
      resetPerson: function(side, role) {
        var con = this;
        var yesCallback = function() {
          con.resetPersonAction(side, role);
          if(role === 'coach') {
            con.resetPersonAction(side, "assistant");
            con.resetPersonAction(side, "representative");
          }
          refreshContext();
        };
        alerts.confirmation({ title: 'Potrditev', body: preMatch.data.resetBodyText[role], yesCallback: yesCallback });
      },
      captain: function(side) {
        for(var p of this[side].players) {
          if(p.is_captain) return p;
        }
        return null;
      },
      hasCustomPlayers(side) {
        return this[side] && this[side].customPlayers;
      },
      setCaptainAsCoach: function(side) {
        var cap = this.captain(side);
        if(cap) {
          refreshContext();
          setContext(side+".coach.first_name", cap.first_name)
          setContext(side+".coach.last_name", cap.last_name)
          setContext(side+".coach.status", "without_license")
          refreshContext();
        }
      },
      shouldPersonSign: function(side, role) {
        return ( (['home', 'away'].indexOf(side) >= 0 && role == 'coach') || side == 'assistant_referees' || side == 'referees' ) && this[side][role] && this[side][role].sig && !this.hasPersonSigned(side, role);
      },
      hasPersonSigned: function(side, role) {
        return this[side][role] && this[side][role].sig && this[side][role].sig.status == 'SIGNED';
      },
      personSignedText: function(side, role) {
        if(this.hasPersonSigned(side, role)) {
          return "PODPISANO";
        } else {
          return "ČAKA NA PODPIS";
        }
      },
      arePlayersLoading(side) {
        return this[side] && this[side].playersLoading && (this[side].players.filter( obj => (obj.first_name || '').length > 0 && (obj.last_name || '').length > 0 ) || []).length == 0;
      },
      hasPlayersLoaded(side) {
        if(!this[side].players || this[side].players.length == 0) {
          return false;
        }
        var hasPlayers = this[side].customPlayers;
        for(let p of this[side].players) {
          hasPlayers = hasPlayers || (p.last_name && p.last_name.length > 0); 
        }
        return hasPlayers;
      },
      hasPhoto: function(side, role) {
        return this.hasPersonLicense(side, role) && getData(side+"."+role+".photo", preMatch.data);
      },
      personPhotoTag: function(side, role, klass) {
        return "<img src='"+this.personPhotoSrc(side, role)+"' class="+(klass || '')+" />";
      },
      personPhotoSrc: function(side, role) {
        var photo = getData( side+"."+role+".photo", preMatch.data );
        return 'data:image/png;base64,'+photo;
      },
      showPhoto: function(side, role) {
        var photo = getData( side+"."+role+".photo", preMatch.data );
        if(photo) {
          var obj = getContext(side+"."+role);
          alerts.info(obj.first_name + " " + obj.last_name, "<p class='text-center'>"+this.personPhotoTag(side, role)+"</p>" );
        }
      },
      canShowQrCode: function(side, role) {
        return this[side][role] && this[side][role].sig;
      },
      showQrCode: function(side, role) {
        if(this.canShowQrCode(side, role)) {
          var person = this[side][role];
          var uuid = person.sig.uuid;
          var url = preMatch.data.urls.signature.replace("XXidXX", uuid);
          alerts.show({ 
            title: person.first_name + " " + person.last_name, 
            body: "<p>Skeniraj QR kodo s telefonom in podpiši!</p><a href='"+url+"' target='_blank'><div class='signature-qr-code' id='"+uuid+"'></div></a>", 
            type: 'info', 
            onShowCallback: function() {
              new QRCode(document.getElementById(uuid), {
                text: url,
                width: 400,
                height: 400
              });
            }
          });
        }
      },
      confirmSigned: function(uuid) {
        for(d of [ ['assistant_referees', 'scorekeeper'], ['assistant_referees', 'scorekeeper_assistant'], ['assistant_referees', 'timekeeper'], ['assistant_referees', 'timekeeper_assistant'], ['home', 'coach'], ['away', 'coach'], ['referees', 'referee1'], ['referees', 'referee2'], ['referees', 'referee3'], ['referees', 'commissioner'] ]) {
          var side = d[0];
          var role = d[1];
          if(this[side] && this[side][role] && this[side][role].sig && this[side][role].sig.uuid == uuid) {
            this.save();
            var $modal = $('.modal.show');
            if($modal.length > 0) {
              if($modal.find('#'+uuid).length > 0) {
                $modal.modal('hide');
              }
            }
            return;
          }
        }
      },
      loadPlayers: function(side) {
        var con = this;
        if(!con.hasPlayersLoaded(side)) {
          con[side].playersLoading = true;
          refreshContext();
          ajaxLoad(preMatch.data.urls[side], function(result) { con[side].customPlayers = result.custom; con[side].players = result.data; preloadImages( con.playerPhotos(side) ); refreshContext(); }, function() { con[side].playersLoading = false; refreshContext(); });
        } else {
          preloadImages( con.playerPhotos(side) );
        }
      },
      updateColors: function() {
        preMatch.updateColors($.extend({}, preMatch.data.home.defaultColor, this.home.color || {}), $.extend({}, preMatch.data.away.defaultColor, this.away.color || {}));
      },
      save: function(successCallback) {
        if(context.saving) {
          context.saveOnSuccess = true;
          return
        }
        context.saving = true;
        var con = context;
        $.ajax(preMatch.data.urls.save, {
          method: 'PUT',
          data: {
            "match[settings]": con.json()
          },
          success: function(data) {
            data.settings.home.players = context.home.players;
            data.settings.home.customPlayers = context.home.customPlayers;
            data.settings.home.playersLoading = context.home.playersLoading;
            data.settings.away.players = context.away.players;
            data.settings.away.playersLoading = context.away.playersLoading;
            data.settings.away.customPlayers = context.away.customPlayers;
            $.extend(context, data.settings);
            con.saving = false;
            if(con.saveOnSuccess) {
              con.saveOnSuccess = false;
              con.save();
            }
            refreshContext();
            if(successCallback) {
              successCallback();
            }
          },
          error: function(xhr, ajaxOptions, thrownError) {
            if(xhr.status == 403) {
              alerts.warning("<h5>Tekma je bila dodeljena drugemu pomožnemu sodniku, zato nimate več pravic za urejanje tekme!</h5><p><small>Čez nekaj sekund boste avtomatasko preusmerjeni na stran s tekmami!</small></p>");
              setTimeout(function() { window.location = "/" }, 10000);
            }
          },
          complete: function() {
            con.saving = false;
          }
        });
      }
    };

    $.ajax(preMatch.data.urls.edit, {
      success: function(data) {
        if(data.redirectUrl) {
          window.location = data.redirectUrl; 
        } else {
          preMatch.initAfterLoad(data.settings);
          setContext("loaded", true);
          refreshContext();
        }
      }
    })
  },
  initAfterLoad: function(settings) {
    preMatch.initLicenseInputs();

    if(preMatch.data.isCustom && settings) {
      for(type of ['home', 'away']) {
        if(settings[type] && settings[type].players) {
          settings[type].players = settings[type].players.sort(function(a,b) { return a.fms_id < b.fms_id ? -1 : 1; }  )
        }
      }
    }
    context = $.extend(context, settings, { saving: false });


    // if empty get from ajax
    // if(!context.referees) {
    //   preMatch.loadReferees();
    // }

    context.loadPlayers('home');
    context.loadPlayers('away');

    // if(!context.hasPlayersLoaded('home')) {
    //   context.home.playersLoading = true;
    //   ajaxLoad(preMatch.data.urls.home, function(result) { context.home.customPlayers = result.custom; context.home.players = result.data; preloadImages( context.playerPhotos('home') ); refreshContext(); }, function() { context.home.playersLoading = false; refreshContext(); });
    // } else {
    //   preloadImages( context.playerPhotos('home') );
    // }

    // if(!context.hasPlayersLoaded('away')) {
    //   context.away.playersLoading = true;
    //   ajaxLoad(preMatch.data.urls.away, function(result) { context.away.customPlayers = result.custom; context.away.players = result.data; preloadImages( context.playerPhotos('away') );refreshContext(); }, function() { context.away.playersLoading = false; refreshContext(); });
    // } else {
    //   preloadImages( context.playerPhotos('away') );
    // }

    preMatch.updatePinFields();
    context.updateColors();

    $('select.colorpicker').simplecolorpicker({picker: true, theme: 'fontawesome'}).on('change', function() {
      var value = $(this).val();
      setContext($(this).data('type') + ".color." + $(this).data('colortype'), value);

      context.updateColors();
    });

    // setInterval(context.save, 20*1000); // autosave every 20s
  },
  loadReferees: function(type) {
    var url = preMatch.data.urls.referees + (type ? "?type="+type : "")
    ajaxLoad(url, function(result) { context.referees = $.extend(context.referees || {}, result.data); refreshContext(); });
  },
  initLicenseInputs: function() {
    $('.license-field').each(function() {
      var $input = $(this);
      var data = $input.data();

      $input.pincodeInput({inputs: data.n, hidedigits: false, complete: function(value) {
        context.syncField(data.field, value);
      }});

      if($input.hasClass('form-control-sm')) {
        $input.parent().find('.pincode-input-text').addClass('form-control-sm');
      }
    });
  },
  updatePinFields: function() {
    $('.person-with-license').each(function() {    
      var $self = $(this);
      var side = $self.data('side');
      var role = $self.data('role');

      var i = 0;
      if(context[side] && context[side][role]) {
        var lic = context[side][role].checked_number;
        var has_license = context[side][role].status == "with_license";
        if(lic && has_license) {
          $self.find('.pincode-input-text').each(function() {
            $(this).val( lic.charAt(i++) );
          });

          let type = 'coach';
          if(side == 'assistant_referees') {
            type = 'assistant_referee';
          } else if (side == 'statisticians') {
            type = 'statistician';
          }

          preMatch.validatePerson(side, role, lic, type);
        }
      }
    });
  },
  updateColors: function(home, away) {
    $('.colorpicker[data-type=home][data-colortype=bg]').val(home.bg);
    $('.colorpicker[data-type=home][data-colortype=text]').val(home.text);
    $('.colorpicker[data-type=away][data-colortype=bg]').val(away.bg);
    $('.colorpicker[data-type=away][data-colortype=text]').val(away.text);

    var $style = $('style#colors');
    var html = ".home-team { color: "+home.text+"; background-color: "+home.bg+"; }";
    html += ".home-jersey { color: "+home.text+"; fill: "+home.bg+";}"

    html += ".away-team { color: "+away.text+"; background-color: "+away.bg+"; }";
    html += ".away-jersey { color: "+away.text+"; fill: "+away.bg+";}"

    $style.html(html);
  },
  validatePerson: function(side, role, number, type) {
    setContext(side+"."+role+".checking", true);
    $.ajax(preMatch.data.urls.validations, {
      data: { 
        type: type,
        number: number,
        competition_id: preMatch.data.competition_id,
        match_id: preMatch.data.match_id,
        side: side
      },
      success: function(data) {
        if(data.status == 'OK') {
          var d = data.data;
          if(d.status_code == 'OK') {
            setContext(side+"."+role+".first_name", d.object.firstname);
            setContext(side+"."+role+".last_name", d.object.lastname);
          } else if(d.status_code == 'NO_LICENSE') {
            alerts.warning(d.msg + "<p></p><p>Če se niste zmotili pri vnosu št. licence, potem izberite \"NIMA licence\" in ročno vnesite ime in priimek.</p>");
            setContext(side+"."+role+".first_name", "");
            setContext(side+"."+role+".last_name", "");
          } else if(d.status_code == 'INSUFFICIENT_LICENSE_LEVEL') {
            alerts.warning(d.msg);
            setContext(side+"."+role+".first_name", d.object.firstname);
            setContext(side+"."+role+".last_name", d.object.lastname);
          }
          setContext(side+"."+role+".license_status", d.status);
          setContext(side+"."+role+".checked_number", number);

          // cache photo
          setData(side+"."+role+".photo", d.object ? d.object.photo : null, preMatch.data);
        }
      },
      complete: function() {
        setContext(side+"."+role+".checking", false);
      }
    });
  },
  validateCoach: function(side, role, number) {
    preMatch.validatePerson(side, role, number, 'coach');
  },
  validateAssistantReferee: function(side, role, number) {
    preMatch.validatePerson(side, role, number, 'assistant_referee');
  },
  validateStatistician: function(side, role, number) {
    preMatch.validatePerson(side, role, number, 'statistician');
  }
}

global.scoresheet = {
  data: {
    state: {
      FT_PRESSED: "FT_PRESSED",
      PT2_PRESSED: "PT2_PRESSED",
      PT3_PRESSED: "PT3_PRESSED",
      FOUL_PRESSED: "FOUL_PRESSED",
      FTFOUL_PRESSED: "FTFOUL_PRESSED",
      UFOUL_PRESSED: "UFOUL_PRESSED",
      TFOUL_PRESSED: "TFOUL_PRESSED",
      EJECTION_PRESSED: "EJECTION_PRESSED",
      FIGHT_PRESSED: "FIGHT_PRESSED",
      PLAYER_SELECTED: "PLAYER_SELECTED",
      COACH_SELECTED: "COACH_SELECTED",
      ASSISTANT_SELECTED: "ASSISTANT_SELECTED",
      OTHER_SELECTED: "OTHER_SELECTED",
      TIMEOUT_TAKEN: "TIMEOUT_TAKEN",
      REMOVE_TIMEOUT: "REMOVE_TIMEOUT",
      CHANGE_CAPTAIN: "CHANGE_CAPTAIN",
      CHALLENGE_TAKEN: "CHALLENGE_TAKEN"
    },
    stateName: {
      FT_PRESSED: "Zadet prosti met",
      PT2_PRESSED: "Zadet met za 2 točki",
      PT3_PRESSED: "Zadet met za 3 točke",
      FOUL_PRESSED: "Osebna napaka brez prostih metov",
      FTFOUL_PRESSED: "Osebna napaka s prostimi meti",
      UFOUL_PRESSED: "Nešportna osebna napaka",
      TFOUL_PRESSED: "Tehnična napaka",
      EJECTION_PRESSED: "Direktna izključitev",
      FIGHT_PRESSED: "Pretep",
      PLAYER_SELECTED: "Igralec izbran",
      COACH_SELECTED: "Trener je izbran",
      ASSISTANT_SELECTED: "Pomočnik trenerja je izbran",
      OTHER_SELECTED: "Nekdo od ostalih je izbran",
      TIMEOUT_TAKEN: "Minuta odmora",
      REMOVE_TIMEOUT: "Odvzeta minuta odmora",
      CHANGE_CAPTAIN: "Zamenjava kapetana",
      CHALLENGE_TAKEN: "HCC"
    },
    stateNameExtra: {
      FT_PRESSED: "Izberi igralca...",
      PT2_PRESSED: "Izberi igralca...",
      PT3_PRESSED: "Izberi igralca...",
      FOUL_PRESSED: "Izberi igralca...",
      FTFOUL_PRESSED: "Izberi igralca...",
      UFOUL_PRESSED: "Izberi igralca...",
      TFOUL_PRESSED: "Izberi igralca, trenerja, pomočnika ali ostale...",
      EJECTION_PRESSED: "Izberi igralca, trenerja, pomočnika ali ostale...",
      FIGHT_PRESSED: "Izberi igralca...",
      PLAYER_SELECTED: "Izberi dogodek...",
      COACH_SELECTED: "Izberi dogodek...",
      ASSISTANT_SELECTED: "Izberi dogodek...",
      OTHER_SELECTED: "Izberi dogodek...",
      TIMEOUT_TAKEN: "Izberi minuto ali potrdi minuto s preslednico...",
      REMOVE_TIMEOUT: "Izberi minuto ali potrdi minuto s preslednico...",
      CHANGE_CAPTAIN: "Izberi igralca, ki bo novi kapetan...",
      CHALLENGE_TAKEN: "Izberi minuto ali potrdi minuto s preslednico...",
    },
    logs: {
      withMinute: function(minute, q, str) {
        return scoresheet.quarterName(q) + " | " + minute + "' | " + str;
      },
      home: 'Domači',
      away: 'Gosti',
      coach: 'Trener',
      other: 'Ostali na klopi',
      assistant: "Pomočnik",
      actions: {
        changeFouls: {
          name: function(log) { return "Os. napaka brez pr. metov"; },
          text: function(log) {
            return scoresheet.data.logs.withMinute(log.minute, log.quarter, scoresheet.playerNameWithNumber(log.pid) + " je storil osebno napako. Ni prostih metov.");
          }
        },
        changeFTFouls: {
          name: function(log) { return "Os. napaka s pr. meti"; },
          text: function(log) {
            return scoresheet.data.logs.withMinute(log.minute, log.quarter, scoresheet.playerNameWithNumber(log.pid) + " je storil osebno napako. Sledijo prosti meti.");
          }
        },
        changeTFouls: {
          name: function(log) { return "Teh. napaka"; },
          text: function(log) {
            return scoresheet.data.logs.withMinute(log.minute, log.quarter, scoresheet.playerNameWithNumber(log.pid) + " je prejel tehnično napako.");
          }
        },
        changeUFouls: {
          name: function(log) { return "Nešp. napaka"; },
          text: function(log) {
            return scoresheet.data.logs.withMinute(log.minute, log.quarter, scoresheet.playerNameWithNumber(log.pid) + " je storil nešportno osebno napako.");
          }
        },
        changeEjections: {
          name: function(log) { return "Izključitev"; },
          text: function(log) {
            return scoresheet.data.logs.withMinute(log.minute, log.quarter, scoresheet.playerNameWithNumber(log.pid) + " je bil izključen iz tekme.");
          }
        },
        changeFights: {
          name: function(log) { return "Pretep"; },
          text: function(log) {
            return scoresheet.data.logs.withMinute(log.minute, log.quarter, scoresheet.playerNameWithNumber(log.pid) + " je bil v udeležen v pretep.");
          }
        },
        changeScore: {
          name: function(log) { 
            if(log.diff === 0) {
              return "Zgrešen prosti met";
            } else if (log.diff === 1) {
              return "Zadet prosti met";
            } else {
              return "Koš za " + log.diff; 
            }
          },
          text: function(log) {
            if(log.diff === 0) {
              return scoresheet.data.logs.withMinute(log.minute, log.quarter, scoresheet.playerNameWithNumber(log.pid) + " je zgrešil prosti met.");
            } else if (log.diff === 1) {
              return scoresheet.data.logs.withMinute(log.minute, log.quarter, scoresheet.playerNameWithNumber(log.pid) + " je zadel prosti met.");
            } else {
              return scoresheet.data.logs.withMinute(log.minute, log.quarter, scoresheet.playerNameWithNumber(log.pid) + " je zadel za "+log.diff+".");
            }
          }
        },
        cancelFt: {
          name: function(log) { return "Preklican prosti met"; },
          text: function(log) {
            return scoresheet.data.logs.withMinute(log.minute, log.quarter, "Igralcu " + scoresheet.playerNameWithNumber(log.pid) + " je bil preklican prosti met.");
          }
        },
        changeTimeouts: {
          name: function(log) { return "Minuta odmora"; },
          text: function(log) {
            var team = log.type == 'home' ? "Domača" : "Gostujoča";
            return scoresheet.data.logs.withMinute(log.minute, log.quarter, team + " ekipa je vzela minuto odmora.");
          }
        },
        changeConfirmTeamSelection: {
          name: function(log) { return "Potrjeni ekipi C in D"; },
          text: function(log) {
            var team = log.type == 'home' ? "domačo" : "gostujočo";
            return `Potrjeni ekipi C in D za ${team} ekipo.`;
          }
        },
        removeTimeout: {
          name: function(log) { return "Odvzeta minuta odmora"; },
          text: function(log) {
            var team = log.type == 'home' ? "Domači" : "Gostujoči";
            return scoresheet.data.logs.withMinute(log.minute, log.quarter, team + " ekipi je bila odvzeta minuta odmora.");
          }
        },
        changeQuarter: {
          name: function(log) { return "Konec četrtine"; },
          text: function(log) {
            var matchFinishedText = log.matchFinished ? " Konec tekme." : "";
            if(scoresheet.isOT(log.quarter)) {
              return "Zaključen " + scoresheet.quarterName(log.quarter) + "." + matchFinishedText;
            } else {
              return "Zaključena " + scoresheet.quarterName(log.quarter) + "." + matchFinishedText;
            }
          }
        },
        changeChallenge: {
          name: function(log) { return "HCC"; },
          text: function(log) { return "HCC"; }
        },
        finishMatch: {
          name: function(log) { return "Konec tekme"; },
          text: function(log) {
            return "Konec tekme."
          }
        },
        changeBenchTFouls: {
          name: function(log) { return "Teh. napaka"; },
          text: function(log) {
            var txt = context.altBenchName(log.type, log.coachIndex) + " je prejel tehnično napako.";
            if(log.mark === 'B' && log.staffType === scoresheet.data.COACH) {
              txt += " Pripiše se jo trenerju!";
            }
            return scoresheet.data.logs.withMinute(log.minute, log.quarter, txt);
          }
        },
        changeBenchEjections: {
          name: function(log) { return "Izključitev"; },
          text: function(log) {
            var txt = context.altBenchName(log.type, log.coachIndex) + " je izključen.";
            return scoresheet.data.logs.withMinute(log.minute, log.quarter, txt);
          }
        },
        setFirstOffense: {
          name: function(log) { return "Prvi napad"; },
          text: function(log) {
            return "Prvi napad za " + (log.type == 'home' ? 'domačo' : 'gostujočo') + " ekipo.";
          }
        },
        changeOffense: {
          name: function(log) { return "Puščica"; },
          text: function(log) {
            return "Obrnjena puščica.";
          }
        },
        changePlayed: {
          name: function(log) { return "Vstop v igro"; },
          text: function(log) {
            return scoresheet.data.logs.withMinute(log.minute, log.quarter, scoresheet.playerNameWithNumber(log.pid) + " je prvič vstopil v igro.");
          }
        },
        changeStarting5: {
          name: function(log) { return "Začne med prvih 5"; },
          text: function(log) {
            return scoresheet.data.logs.withMinute(log.minute, log.quarter, scoresheet.playerNameWithNumber(log.pid) + " bo začel v prvi peterki.");
          }
        },
        changeCaptain: {
          name: function(log) { return "Zamenjava kapetana"; },
          text: function(log) {
            return scoresheet.data.logs.withMinute(log.minute, log.quarter, "Zamenjava " + (log.type === 'home' ? 'domačega' : 'gostujočega') + " kapetana.");
          }
        }
      }
    },
    ftType: {
      extra: {
        key: "extra",
        name: "Dodaten po zadetem košu"
      },
      technical: {
        key: "technical",
        name: "Zaradi tehnične napake"
      },
      extra2: {
        key: 'extra',
        name: 'En prosti met'
      },
      separator1: {
        key: "separator",
        name: "────────────────────"
      },
      one_of_two: {
        key: "one_of_two",
        name: "Prvi od dveh"
      },
      two_of_two: {
        key: "two_of_two",
        name: "Drugi od dveh"
      },
      separator2: {
        key: "separator",
        name: "────────────────────"
      },
      one_of_three: {
        key: "one_of_three",
        name: "Prvi od treh"
      },
      two_of_three: {
        key: "two_of_three",
        name: "Drugi od treh"
      },
      three_of_three: {
        key: "three_of_three",
        name: "Tretji od treh"
      }
    },
    timeouts: {
      h1: 2,
      h2: 3,
      q5: 1,
      q6: 1,
      q7: 1,
      q8: 1,
      q9: 1
    },
    challengeCount: 1,
    bonus: 4,
    resetScoreEveryQuarter: false,
    fouledOut: 5,
    minutesPerQuarter: 10,
    numberOfQuarters: 4,
    minutesPerOT: 5,
    // maxPlayersOnCourt: 5,
    COACH: 'coach',
    OTHER: 'other',
    ASSISTANT: 'assistant',
    REPRESENTATIVE: 'representative',
    playerDefaults: function() {
      return {
        cft: 0,
        mft: 0,
        ft: 0,
        pt2: 0,
        pt3: 0,
        foulTypes: ["", "", "", "", "", "", ""],
        fouls: 0,
        ufouls: 0,
        tfouls: 0,
        ejections: 0,
        fights: 0,
        playedSelected: false,
        played: false,
        isStarting: false
      };
    },
    benchDefaults: function() {
      return {
        bfouls: 0,
        cfouls: 0,
        ejections: 0,
        foulTypes: ["", "", "", "", ""]
      };
    },
    teamDefaults: function(color, bench) {
      return {
        color: color,
        bench: bench,
        score: {
          q1: 0,
          q2: 0,
          q3: 0,
          q4: 0,
          q5: 0,
          q6: 0,
          q7: 0,
          q8: 0,
          q9: 0
        },
        fouls: {
          q1: 0,
          q2: 0,
          q3: 0,
          q4: 0,
          q5: 0,
          q6: 0,
          q7: 0,
          q8: 0,
          q9: 0
        },
        bonus: {
          q1: false,
          q2: false,
          q3: false,
          q4: false
        },
        timeouts: {
          h1: 0,
          h2: 0,
          q5: 0,
          q6: 0,
          q7: 0,
          q8: 0,
          q9: 0
        },
        challengeCount: 0
      }
    }
  },
  initPlayers: function() {
    for(pid in scoresheet.data.playerId2data) {
      var p = scoresheet.player(pid);
      var data = context[pid] ? context[pid] : { isCaptain: p.is_captain, number: p.number };
      context[pid] = $.extend(data, scoresheet.data.playerDefaults());  // override with defaults
    }
  },
  initBench: function() {
    var types = ['home', 'away'];
    var tmp = {
      home: [],
      away: []
    }
    for(type of types) {
      if(scoresheet.data.side2type2data[type][scoresheet.data.COACH]) {
        tmp[type].push($.extend({
          originalRole: scoresheet.data.COACH,
          firstName: scoresheet.data.side2type2data[type][scoresheet.data.COACH].firstName,
          lastName: scoresheet.data.side2type2data[type][scoresheet.data.COACH].lastName,
          role: scoresheet.data.COACH
        }, scoresheet.data.benchDefaults()));
      }

      if(scoresheet.data.side2type2data[type][scoresheet.data.ASSISTANT]) {
        tmp[type].push($.extend({
          originalRole: scoresheet.data.ASSISTANT,
          firstName: scoresheet.data.side2type2data[type][scoresheet.data.ASSISTANT].firstName,
          lastName: scoresheet.data.side2type2data[type][scoresheet.data.ASSISTANT].lastName,
          role: scoresheet.data.ASSISTANT
        }, scoresheet.data.benchDefaults()));
      } else {
        tmp[type].push($.extend({
          originalRole: scoresheet.data.ASSISTANT,
          firstName: null,
          lastName: null,
          role: null
        }, scoresheet.data.benchDefaults()));
      }
    }

    for(var i = 0; i < 17; i++) {
      for(type of types) {
        tmp[type].push($.extend({
          name: scoresheet.data.logs.other,
          originalRole: scoresheet.data.OTHER,
          role: (i === 0 ? scoresheet.data.OTHER : null)
        }, scoresheet.data.benchDefaults()));
      }
    }
    for(type of types) {
      if(scoresheet.data.side2type2data[type][scoresheet.data.REPRESENTATIVE]) {
        tmp[type].push({
          originalRole: scoresheet.data.REPRESENTATIVE,
          firstName: scoresheet.data.side2type2data[type][scoresheet.data.REPRESENTATIVE].firstName,
          lastName: scoresheet.data.side2type2data[type][scoresheet.data.REPRESENTATIVE].lastName,
          role: scoresheet.data.REPRESENTATIVE
        });
      }
    }
    return tmp;
  },
  setDefaults: function() {
    // SET PLAYERS
    scoresheet.initPlayers();

    // SET HOME AND AWAY
    var tmp = scoresheet.initBench();
    context.home = scoresheet.data.teamDefaults( context.home.color, tmp['home'] );
    context.away = scoresheet.data.teamDefaults( context.away.color, tmp['away'] );
  },
  init: function(saveUrl, timeUrl, inviteUrl, signatureUrl, reportInviteUrl, matchId) {
    scoresheet.data.saveUrl = saveUrl;
    scoresheet.data.timeUrl = timeUrl;
    scoresheet.data.inviteUrl = inviteUrl;
    scoresheet.data.signatureUrl = signatureUrl;
    scoresheet.data.reportInviteUrl = reportInviteUrl;
    scoresheet.data.matchId = matchId;
        
    $.ajax(scoresheet.data.saveUrl, {
      success: function(data) {
        // take scoresheetobj from localstorage if later saved then on server!
        let obj = scoresheet.getFromLocalStorage();
        let scoresheetObj = data.scoresheetObj;
        if(data.scoresheetObj.serverSaveTime && obj.scoresheetObj.localStorageSaveTime && data.scoresheetObj.serverSaveTime < obj.scoresheetObj.localStorageSaveTime) {
          scoresheetObj = obj.scoresheetObj;
        }

        scoresheet.initAfterLoad(data.playerId2data, scoresheetObj, data.side2type2data, data.rules);
        setContext("loaded", true);
        setContext("isError", false);
        refreshContext();
      },
      error: function() {
        setContext("isError", true);
      }
    });

  },
  initAfterLoad: function(playerId2data, scoresheetObj, side2type2data, rules) {
    scoresheet.data.actionStates = [scoresheet.data.state.FT_PRESSED, scoresheet.data.state.PT2_PRESSED, scoresheet.data.state.PT3_PRESSED, scoresheet.data.state.FOUL_PRESSED, scoresheet.data.state.FTFOUL_PRESSED, scoresheet.data.state.UFOUL_PRESSED, scoresheet.data.state.TFOUL_PRESSED, scoresheet.data.state.EJECTION_PRESSED, scoresheet.data.state.FIGHT_PRESSED, scoresheet.data.state.TIMEOUT_TAKEN, scoresheet.data.state.REMOVE_TIMEOUT]
    scoresheet.data.entityStates = [scoresheet.data.state.PLAYER_SELECTED, scoresheet.data.state.COACH_SELECTED, scoresheet.data.state.ASSISTANT_SELECTED, scoresheet.data.state.OTHER_SELECTED]
    scoresheet.data.playerId2data = playerId2data;
    scoresheet.data.side2type2data = side2type2data;    // { home: { coach: {...}, assistant: {...} }, away: { coach: {...}, assistant: {...} } }

    if(rules.bonus) {
      scoresheet.data.bonus = rules.bonus;
    }
    scoresheet.data.resetScoreEveryQuarter = rules.reset_score_every_quarter;

    // INIT PLAYERS
    scoresheet.initPlayers();

    // INIT HOME AND AWAY BENCH
    var tmp = scoresheet.initBench();

    context = $.extend(context, {
      ft: {},
      showPlayerNames: true,
      fullScreen: false,
      logId: 0,
      saving: false,
      saveOnSuccess: false,
      left: 'home',
      right: 'away',
      playerId: null,               // for player_selected
      ttype: null,                   // for coach_selected and ASSISTANT_SELECTED
      selectedBenchIndex: null,               // when ttype != null, this gives us who is selected
      previousState: null,
      previousPreviousState: null,
      state: null,
      quarter: 1,
      minute: 1,
      matchFinished: false,
      firstOffense: null,
      nextOffense: null,
      waitingForMinute: false,
      home: scoresheet.data.teamDefaults( side2type2data.home.color || { bg: '#007bff', text: '#ffffff' }, tmp['home'] ),
      away: scoresheet.data.teamDefaults( side2type2data.away.color || { bg: '#dc3545', text: '#ffffff' }, tmp['away'] ),
      referees: [],
      logs: [],
      logTable: {
        limit: 10,
        page: 1,
        filters: {
          quarter: null,
          minute: null,
          state: null,
          type: null,
          number: null,
          entity: null,          // igralec, trener, pomočnik, nekdo od ostalih
          numberEntity: null
        }
      },
      edit: {},
      benchLabel: function(type, i) {
        var b = this.bench(type, i);
        if(this.isCoach(type, i) || b.originalRole === scoresheet.data.COACH && this.isBenchEjected(type, i)) {
          return "T";
        } else if(b.originalRole === scoresheet.data.ASSISTANT) {
          return "P";
        } else {
          return "O";
        }
      },
      bench: function(type, i) {
        return this[type].bench[i];
      },
      benchIndex: function(type, role) {
        for(var i = 0; this[type].bench.length; i++) {
          var b = this.bench(type, i);
          if(b && b.role == role) return i;
        }
        return null;
      },
      nextEligibleBenchIndex: function(type, start) {
        for(var i = start; i < this[type].bench.length; i++) {
          var b = this.bench(type, i);
          if(!this.isBenchEjected(type, i) && !(b.originalRole == scoresheet.data.ASSISTANT && b.role == null)) return i;
        }
        return null;
      },
      coach: function(type) {
        for(var i = 0; this[type].bench.length; i++) {
          if(this.isCoach(type, i)) return this.bench(type, i);
        }
        return null;
      },
      coachIndex: function(type) {
        for(var i = 0; this[type].bench.length; i++) {
          if(this.isCoach(type, i)) return i;
        }
        return null;
      },
      isPlayerBenchEjected: function(pid) {
        // check if player was ejected as a coach, then he must leave the hall, and thus cannot participate in the game in any role
        var type = this.type(pid);
        for(var i = 0; i < this[type].bench.length; i++) {
          if(this.bench(type, i).pid == pid && this.isBenchEjected(type, i)) return true;
        }
        return false;
      },
      isBenchEjected: function(type, i) {
        var b = this.bench(type, i);
        return b.ejections >= 1 || b.cfouls >= 2 || (b.cfouls + b.bfouls >= 3); 
      },
      isBenchVisible: function(type, i) {
        var b = this.bench(type, i);
        return b.role || this.isBenchEjected(type,i);
      },
      setBenchRole: function(bench, role) {
        bench.previousRole = bench.role;
        bench.role = role;
      },
      setCoach: function(bench, isOther, type) {
        this.setBenchRole(bench, scoresheet.data.COACH);
        if(isOther) {
          // must set captain, or if captain was already ejected as a coach, then another player must be selected
          var pid = this.currentCaptain(type);
          if(pid) {
            var pdata = scoresheet.player(pid);
            bench.firstName = pdata.first_name;
            bench.lastName = pdata.last_name;
            bench.pid = pid;
          } else {
            // should never get here??? 
          }
        }
      },
      setAssistant: function(bench) { 
        this.setBenchRole(bench, scoresheet.data.ASSISTANT);
      },
      setOther: function(bench) {
        this.setBenchRole(bench, scoresheet.data.OTHER);
      },
      setRoleNull: function(bench) {
        this.setBenchRole(bench, null);
      },
      setAssistantAndOtherRoles: function(bench1, bench2) {
        if(this.isOriginallyAssistant(bench1)) {
          this.setAssistant(bench1);
          this.setOther(bench2);
        } else {
          this.setOther(bench1);
          this.setRoleNull(bench2);
        }
      },
      benchName: function(type, i, lastNameFirst, withInitials) {
        var b = this.bench(type, i);
        if(!this.isOther(b) && b.firstName && b.lastName) {
          return scoresheet.name(b.firstName, b.lastName, lastNameFirst, withInitials);
        } else {
          return b.name;
        }
      },
      altBenchName: function(type, i, lastNameFirst, withInitials) {
        var name = this.benchName(type, i, lastNameFirst, withInitials);
        return name === scoresheet.data.logs.other ? "Nekdo od ostalih" : name;
      },
      benchFouls: function(type, i) {
        var b = this.bench(type, i);
        return b.bfouls + b.cfouls + b.ejections;
      },
      isCaptain: function(pid, type) {
        return pid == this.currentCaptain(type);
      },
      youngestTeamLabel: function(pid) {
        var arr = [];
        var p1 = scoresheet.player(pid);
        var p2 = this[pid];
        if(this.quarter <= 2) {
          if( (p1 && p1.in_a) || (p2 && p2.in_a) ) {
            arr.push('A');
          }
          if( (p1 && p1.in_b) || (p2 && p2.in_b) ) {
            arr.push('B');
          }
        } else {
          if( (p1 && p1.in_c) || (p2 && p2.in_c) ) {
            arr.push('C');
          }
          if( (p1 && p1.in_d) || (p2 && p2.in_d) ) {
            arr.push('D');
          }
        }
        return arr.join(",");
      },
      playerName: function(pid, lastNameFirst, withInitials) {
        return scoresheet.playerName(pid, lastNameFirst, withInitials);
      },
      isWithoutCaptain: function(type) {
        return this.currentCaptain(type) == null;
      },
      currentCaptain: function(type) {
        for(pid in scoresheet.data.playerId2data) {
          var pdata = scoresheet.player(pid);
          var p = this[pid];
          if(pdata.type == type && p.isCaptain && !this.isEjected(pid)) {
            return pid;
          }
        }
        return null;
      },
      typeName: function(type) {
        return "("+ scoresheet.data.logs[type] +")";
      },
      realScore: function(type) {
        var total = 0;
        for(key in this[type].score) {
          total += this[type].score[key];
        }
        return total;
      },
      finalYoungestScore: function(type) {
        var otherType = this.otherType(type);

        var finalYoungestScoreType = 0;
        var finalYoungestScoreOtherType = 0;

        for(var i = 1; i <= 4; i++) {
          finalYoungestScoreType += this.quarterYoungestScore(type, i);
          finalYoungestScoreOtherType += this.quarterYoungestScore(otherType, i);
        }

        if(finalYoungestScoreType == finalYoungestScoreOtherType) {
          var finalRealScoreType = this.realScore(type);
          var finalRealScoreOtherType = this.realScore(otherType);

          if(finalRealScoreType > finalRealScoreOtherType) {
            return finalYoungestScoreType+1;
          } else {
            return finalYoungestScoreType;
          }
        } else {
          return finalYoungestScoreType;
        }
      },
      quarterYoungestScore: function(type, q) {
        var otherType = this.otherType(type);

        var realScoreType = this[type].score["q"+q];
        var realScoreOtherType = this[otherType].score["q"+q];

        if(realScoreType > realScoreOtherType) {
          return 3;
        } else if(realScoreType == realScoreOtherType) {
          return 2;
        } else {
          return 1;
        }
      },
      score: function(type) {
        if(scoresheet.data.resetScoreEveryQuarter) {
          if(this.matchFinished || this.quarter > 4) {
            return this.finalYoungestScore(type);
          } else {
            return this[type].score["q"+this.quarter];
          }
        } else {          
          return this.realScore(type);
        }
      },
      scoreTextByQuarters: function() {
        var ltype = this.left;
        var rtype = this.right;
        var scores = [];
        var n = this.quarter - (this.matchFinished ? 1 : 0);
        for(var i = 1; i <= n; i++) {
          if(i < this.quarter && scoresheet.data.resetScoreEveryQuarter) {
            scores.push( this.quarterYoungestScore(ltype, i) + " - " + this.quarterYoungestScore(rtype, i) );
          } else {
            scores.push( this[ltype].score["q"+i] + " - " + this[rtype].score["q"+i] );
          }
        }
        return scores.join(" ; ");
      },
      quarterName: function() {
        if(this.matchFinished) {
          return "Konec tekme";
        } else {
          return scoresheet.quarterName(this.quarter);
        }
      },
      lastLogText: function() {
        if(this.hasLogs()) {
          var log = this.reversedLog(0);
          return this.logActionText(log);
        } else {
          return "/";
        }
      },
      isActionState: function(state) {
        return scoresheet.data.actionStates.indexOf( state ) >= 0;
      },
      isEntityState: function(state) {
        return scoresheet.data.entityStates.indexOf( state ) >= 0;
      },
      entityStateText: function(state, withExtra) {
        var txt = "";
        if(state === scoresheet.data.state.PLAYER_SELECTED) {
          txt = scoresheet.playerNameWithNumber(this.playerId);
        } else if(state === scoresheet.data.state.COACH_SELECTED) {
          txt = "Trener";
        } else if(state === scoresheet.data.state.ASSISTANT_SELECTED) {
          txt = "Pomočnik";
        } else if(state === scoresheet.data.state.OTHER_SELECTED) {
          txt = "Nekdo od ostalih";
        } else {
          return "[NO ENTITY]";
        }
        return txt + (withExtra ? " | " + scoresheet.data.stateNameExtra[this.state] : "");
      },
      actionStateText: function(state, withExtra) {
        return scoresheet.data.stateName[state] + (withExtra ? " | " + scoresheet.data.stateNameExtra[this.state] : "");
      },
      currentStateText: function() {
        if(this.state === null) {
          return "/";
        } else if(this.isActionState(this.state) && this.isEntityState(this.previousState)) {
          return this.entityStateText(this.previousState) + " | " + this.actionStateText(this.state) + " | Izberi minuto ali potrdi minuto s preslednico...";
        } else if(this.isActionState(this.previousState) && this.isEntityState(this.state)) {
          return this.entityStateText(this.state) + " | " + this.actionStateText(this.previousState) + " | Izberi minuto ali potrdi minuto s preslednico...";
        } else if(this.isEntityState(this.state)) {
          return this.entityStateText(this.state, true);
        } else if(this.isActionState(this.state) || this.state === scoresheet.data.state.CHANGE_CAPTAIN) {
          return this.actionStateText(this.state, true);
        }
      },
      isPlayerInC: function(pid) {
        var p = this[pid];
        return p && p.in_c;
      },
      onPlayerInCChange: function(el, pid) {
        var p = this[pid];
        if(p) {
          p.in_c = el.checked;
          scoresheet.player(pid).in_c = el.checked;
          refreshContext();
          scoresheet.save();
        }
      },
      isPlayerInD: function(pid) {
        var p = this[pid];
        return p && p.in_d;
      },
      onPlayerInDChange: function(el, pid) {
        var p = this[pid];
        if(p) {
          p.in_d = el.checked;
          scoresheet.player(pid).in_d = el.checked;
          refreshContext();
          scoresheet.save();
        }
      },
      isFoulCommited: function(pid, i) {
        return this[pid].fouls >= i;
      },
      isCoach: function(type, i) {
        return this.bench(type, i).role === scoresheet.data.COACH;
      },
      isPreviousCoach: function(bench) {
        return bench.previousRole === scoresheet.data.COACH;
      },
      isAssistant: function(bench) {
        return bench.role === scoresheet.data.ASSISTANT;
      },
      isPreviousAssistant: function(bench) {
        return bench.previousRole === scoresheet.data.ASSISTANT;
      },
      isBenchAssistant: function(type, i) {
        return this.isAssistant( this.bench(type, i) );
      },
      isOriginallyAssistant: function(bench) {
        return bench.originalRole === scoresheet.data.ASSISTANT;
      },
      isOriginallyOther: function(bench) {
        return bench.originalRole === scoresheet.data.OTHER;
      },
      isOther: function(bench) {
        return bench.role === scoresheet.data.OTHER;
      },
      isPreviousOther: function(bench) {
        return bench.previousRole === scoresheet.data.OTHER;
      },
      isBenchOther: function(type, i) {
        return this.isOther( this.bench(type,i) );
      },
      isBenchFoulCommited: function(type, benchIndex, foulIndex) {
        return this.benchFouls(type, benchIndex) > foulIndex;
      },
      hasBenchFoulMark: function(type, benchIndex, foulIndex) {
        return this.benchFoulMark(type, benchIndex, foulIndex) && this.isBenchFoulCommited(type, benchIndex, foulIndex);
      },
      benchFoulMark: function(type, benchIndex, foulIndex) {
        return this.bench(type, benchIndex).foulTypes && this.bench(type, benchIndex).foulTypes[foulIndex];
      },
      isFoulCancelled: function(pid, i) { // i =1,2,3,4,5
        if(this[pid].fouls < i || ["", "|"].indexOf( this[pid].foulTypes[i-1] ) >= 0) return false; 

        var n = 0;
        for(log of this.logs) {
          if(log.pid == pid && this.isFoul(log)) {
            n++;
          }
          if(n == i) {
            return !!log.cancelledWithId;
          }
        }
        return false;
      },
      isBenchFoulCancelled: function(pid, i) {
        return this.bench(pid, i)
      },
      hasFoulMark: function(pid, i) {
        return this.foulMark(pid, i) && this.isFoulCommited(pid, i);
      },
      foulMark: function(pid, i) {
        return this[pid].foulTypes[i-1];
      },
      teamFoul: function(type, i) {
        return this.foulsInCurrentQuarter(type) >= i ? "X" : "";
      },
      foulsInCurrentQuarter: function(type) {
        return this[type].fouls["q"+this.quarter] + ( this.isOT() ? this[type].fouls.q4 : 0 );
      },
      isBonusInCurrentQuarter: function(type) {
        var qkey = this.isOT() ? 'q4' : 'q' + this.quarter;
        return this[type].bonus && this[type].bonus[qkey];
      },
      isFouledOut: function(pid) {
        var p = this[pid];
        return p.fouls >= scoresheet.data.fouledOut;
      },
      isEjected: function(pid) {
        var p = this[pid];
        return p && ( p.ejections > 0 || p.fights > 0 || p.ufouls + p.tfouls >= 2 || this.isPlayerBenchEjected(pid) );
      },
      isActionSelected: function(state) {
        return this.state === state || (this.state !== null && this.previousState === state && !this.isActionState(this.state));
      },
      isPlayerSelected: function(pid) {
        return this.playerId === pid;
      },
      isBenchSelected: function(type, i) {
        return this.ttype === type && this.selectedBenchIndex === i;
      },
      isDisabled: function(pid) {
        return this.isEjected(pid);   // || this.isFouledOut(pid)
      },
      isLastLogHome: function() {
        return this.isLogOfType( this.reversedLog(0), 'home');
      },
      isLastLogAway: function() {
        return this.isLogOfType( this.reversedLog(0), 'away');
      },
      isLogOfType: function(log, type) {
        return log && log.type === type;
      },
      isArrayLogHome: function(i) {
        return this.isLogOfType( this.reversedLog(i), 'home');
      },
      isArrayLogAway: function(i) {
        return this.isLogOfType( this.reversedLog(i), 'away');
      },
      hasPlayed: function(pid) {
        return this[pid].played;
      },
      checkEligibleToContinue: function(type, pid) {
        if(!this.validateEligible(pid, true) || !this.validateFouledOut(pid)) {
          this.reset();
        }
      },
      checkBenchEligibleToContinue: function(type, index) {
        if(!this.validateBenchEligible(type, index)) {
          this.reset();
        }
      },
      isOT: function() {
        return scoresheet.isOT(this.quarter);
      },
      isMinuteSelected: function(min) {
        return this.minute === min;
      },
      isFirstLogInQuarter: function(quarter) {
        for(log of this.logs) {
          if(log.fn == 'changeConfirmTeamSelection') continue;
          if(log.quarter == quarter) return false;
        }
        return true;
      },
      type: function(pid) {
        return scoresheet.player(pid).type;
      },
      pointsText: function(pid) {
        var n = this.points(pid);
        if(n%100 == 1) {
          return n + " točka";
        } else if(n%100 == 2) {
          return n + " točki";
        } else if(n%100 == 3 || n%100 == 4) {
          return n + " točke";
        } else {
          return n + " točk";
        }
      },
      points: function(pid) {
        var p = this[pid];
        return p.ft + 2*p.pt2 + 3*p.pt3;
      },
      validateFouledOut: function(pid) {
        var is = this.isFouledOut(pid);
        if(is) {
          alerts.warning("<p>" + scoresheet.playerName(pid) + " nima več pravice vstopa na igrišče!</p>");
        } 
        return !is;
      },
      validateWithoutCaptain: function(type, hidePopup) {
        var is = this.isWithoutCaptain(type);
        if(is && !hidePopup) {
          alerts.warning("<p>" + (type == 'home' ? 'Domača' : 'Gostujoča') + " ekipa nima kapetana! Izberite igralca, ki bo novi kapetan.</p>");
        }
        return !is
      },
      validateEligible: function(pid, after) {
        var is = this.isEjected(pid);
        if(is) {
          alerts.warning("<p>" + scoresheet.playerName(pid) + " je izključen iz tekme!</p>");
        } 
        return !is;
      },
      validateBenchEligible: function(type, index) {
        var is = this.isBenchEjected(type, index);
        if(is) alerts.warning("<p>" + this.altBenchName(type, index) + " iz " + (type == 'home' ? 'domače' : 'gostujoče') + " ekipe je izključen iz tekme!</p>");
        return !is;
      },
      validateTimeouts: function(type) {
        var is = this.getTimeoutsLeft(type) > 0;
        if(!is) alerts.warning("<p>Ekipa nima na voljo več nobene minute odmora!</p>");
        return is;
      },
      validateChallenge: function(type) {
        var is = this.getChallengesLeft(type) > 0;
        if(!is) alerts.warning("<p>Ekipa nima na voljo več pregleda posnetka!</p>");
        return is;
      },
      validateMatchFinished: function() {
        var is = !this.matchFinished;
        if(!is) alerts.warning("<p>Tekma je končana!</p>");
        return is;
      },
      validateNextOffense: function() {
        var is = this.isFirstOffenseSet();
        if(!is) alerts.warning("<p>Najprej je potrebno določiti, katera ekipa je imela prvi napad!</p>");
        return is;
      },
      addChangeQuarterLog: function(fn, diff) {
        var log = {
          fn: fn,
          quarter: this.quarter-diff,
          diff: diff,
          inverseArgs: [-diff, true]
        };
        return this.addLog(log);
      },
      addFinishMatchLog: function(fn) {
        var log = {
          fn: fn,
          inverseArgs: [true]
        };
        return this.addLog(log, this.quarter-1, this.maxMinute(this.quarter-1));
      },
      maxMinute: function(q) {
        if(q <= 4) {
          return 10;
        } else {
          return 5;
        }
      },
      addChangeCaptainLog: function(type, pid) {
        var log = {
          fn: "changeCaptain",
          type: type, 
          pid: pid,
          inverseArgs: [type, pid, true]
        };
        return this.addLog(log);
      },
      addFtLog: function(type, pid, diff, ftType, undoPrevious) {
        var log = {
          fn: "changeScore",
          type: type,
          pid: pid,
          diff: diff,
          ftType: ftType,
          undoPrevious: undoPrevious,
          inverseArgs: [type, -diff, pid, true]
        };
        return this.addLog(log);
      },
      addCancelFtLog: function(type, pid, diff, ftType, undoPrevious) {
        var log = {
          fn: "cancelFt",
          type: type,
          pid: pid,
          diff: diff,
          ftType: ftType,
          undoPrevious: undoPrevious,
          inverseArgs: [type, -diff, pid, true]
        };
        return this.addLog(log);
      },
      addChangeLog: function(fn, type, pid, diff, undoPrevious) {
        var log = {
          fn: fn,
          type: type,
          pid: pid,
          diff: diff,
          undoPrevious: undoPrevious,
          inverseArgs: [type, -diff, pid, true]
        };
        return this.addLog(log);
      },
      addChangeBenchLog: function(fn, type, diff, coachIndex, newCoachIndex, newOtherIndex, mark, staffType, undoPrevious) {
        var log = {
          fn: fn,
          type: type,
          diff: diff,
          staffType: staffType,
          coachIndex: coachIndex,
          newCoachIndex: newCoachIndex,
          newOtherIndex: newOtherIndex,
          mark: mark,
          undoPrevious: undoPrevious,
          inverseArgs: [type, -diff, coachIndex, newCoachIndex, newOtherIndex, mark]
        };
        return this.addLog(log);
      },
      addFirstOffenseLog: function(type) {
        var log = {
          fn: "setFirstOffense",
          type: type,
          inverseArgs: [null, true]
        };
        return this.addLog(log);
      },
      addNextOffenseLog: function(newType) {
        var log = {
          fn: "changeOffense",
          type: newType,
          inverseArgs: [true]
        };
        return this.addLog(log);
      },
      addChangeStarting5Log: function(pid, starting5) {
        var log = {
          fn: "changeStarting5",
          pid: pid,
          type: this.type(pid),
          starting5: starting5
        };
        return this.addLog(log);
      },
      addChangePlayedLog: function(pid) {
        var log = {
          fn: "changePlayed",
          pid: pid,
          type: this.type(pid),
          played: this.hasPlayed(pid),
          inverseArgs: [pid, true]
        };
        return this.addLog(log);
      },
      addLog: function(log, q, m) {
        log = $.extend({ id: this.nextId(), minute: m || this.minute, quarter: q || this.quarter }, log);
        if(log.quarter > 1 && log.fn != 'changeOffense' && log.fn != 'changeConfirmTeamSelection' && this.isFirstLogInQuarter(log.quarter)) {
          var con = this;
          var callback = function() {
            con.changeOffense();
          };
          alerts.confirmation({ title: "POZOR", body: "<p>Puščica po začetku četrtine še ni bila obrnjena. Ali jo želiš obrniti?</p>", yesCallback: callback });
        }
        this.logs.push(log);
        refreshContext();
        scoresheet.save();
        return log;
      },
      nextId: function() {
        return ++this.logId;
      },
      undoLog: function(log) {
        if(log.inverseArgs) {
          var undoFn = log.fn + (this[log.fn+"Undo"] ? "Undo" : "")
          this[undoFn](...log.inverseArgs);
          this.endAction();
        }
      },
      canBeUndone: function() {
        return this.hasLogs() && this.reversedLog(0).inverseArgs;
      },
      undo: function() {
        if(this.canBeUndone()) {
          var log = this.logs.pop();
          this.undoLog(log);
          this.undoCancelledFouls(log);
          
          if(this.hasLogs() && log.undoPrevious) {
            this.undo();
          }

          this.reset();
          scoresheet.save();
        }
      },
      hasLogs: function() {
        return this.logs.length > 0;
      },
      reversedLog: function(i) {
        if(this.logs.length > i) {
          return this.logs[this.logs.length - 1 - i];
        } else {
          return null;
        }
      },
      doFiltersApply: function(log) {
        for(filter in this.logTable.filters) {
          var fval = this.logTable.filters[filter];
          if(!fval) continue;

          var lval;
          if(filter === 'state') {
            lval = scoresheet.data.logs.actions[log.fn].name(log) + (log.fn == 'changeScore' ? "|" + log.diff : "");
          } else if(filter === 'number') {
            var p = scoresheet.data.playerId2data[log.pid];
            lval = p && p.number;
          } else if(filter === 'entity') {
            lval = log.pid || log.staffType;
          } else {
            lval = log[filter];
          }

          if(fval != lval) {
            return false;
          }
        }
        return true;
      },
      changePage: function(diff) {
        var newPage = this.logTable.page + diff; 
        if(diff < 0 && newPage < 1) {
          newPage = 1;
        } else if(diff > 0) {
          var maxPage = this.getLogTableMaxPage();
          if(newPage > maxPage) {
            newPage = maxPage;
          }
        }
        setContext("logTable.page", newPage);
      },
      isOfficialMissing: function(role) {
        return this.referees && this.referees[role] && this.referees[role].status === "missing";
      },
      officialName: function(role) {
        if(!this.referees || !this.referees[role] || this.isOfficialMissing(role)) {
          return "";
        } else {
          var s = this.referees[role].first_name + " " + this.referees[role].last_name;
          if(this.referees[role].city) {
            s += " (" + this.referees[role].city + ")";
          }
          return s;
        }
      },
      getObject: function(arr) {
        var obj = this;
        for(var key of arr) {
          obj = obj[key];
          if(!obj) break;
        }
        return obj;
      },
      shouldPersonSign: function(arr) {
        var obj = this.getObject(arr);
        return obj && obj.sig && !this.hasPersonSigned(arr);
      },
      hasPersonSigned: function(arr) {
        var obj = this.getObject(arr);
        return obj && obj.sig && obj.sig.status == 'SIGNED';
      },
      personSignedText: function(arr) {
        if(this.hasPersonSigned(arr)) {
          return "PODPISANO";
        } else {
          return "ČAKA NA PODPIS";
        }
      },
      canShowQrCode: function(arr) {
        var obj = this.getObject(arr);
        return obj && obj.sig;
      },
      showQrCode: function(arr) {
        if(this.canShowQrCode(arr)) {
          var person = this.getObject(arr);
          var uuid = person.sig.uuid;
          var url = scoresheet.data.signatureUrl.replace("XXidXX", uuid);
          alerts.show({ 
            title: person.first_name + " " + person.last_name, 
            body: "<p>Skeniraj QR kodo s telefonom in podpiši!</p><a href='"+url+"' target='_blank'><div class='signature-qr-code' id='"+uuid+"'></div></a>", 
            type: 'info', 
            onShowCallback: function() {
              new QRCode(document.getElementById(uuid), {
                text: url,
                width: 400,
                height: 400
              });
            }
          });
        }
      },
      confirmSigned: function(uuid) {
        for(d of [ ['referees', 'referee1'], ['referees', 'referee2'], ['referees', 'referee3'], ['complaint', 'home', 'captain'], ['complaint', 'away', 'captain'] ]) {
          d.push('sig');
          var sig = this.getObject(d);
          if(sig && sig.uuid == uuid) {
            sig.status = 'SIGNED';
            scoresheet.save();
            var $modal = $('.modal.show');
            if($modal.length > 0) {
              if($modal.find('#'+uuid).length > 0) {
                $modal.modal('hide');
              }
            }
            refreshContext();
            return;
          }
        }
      },
      getLastServerSaveTimeNice: function() {
        if(this.serverSaveTime) {
          return new Date(this.serverSaveTime).toLocaleString("sl-SI");
        } else {
          return "/";
        }
      },
      complaintQuestionText: function(side) {
        if(!this.complaint || !this.complaint[side] || !this.complaint[side].captain) {
          return "";
        }
        if(side == 'home') {
          return "Ali kapetan domačih (" + this.complaint.home.teamName + ") vlaga pritožbo?";
        } else {
          return "Ali kapetan gostov (" + this.complaint.away.teamName + ") vlaga pritožbo?";
        }
      },
      captainName: function(side) {
        if(!this.complaint || !this.complaint[side].captain) {
          return "";
        }
        return this.complaint[side].captain.first_name + " " + this.complaint[side].captain.last_name;
      },
      showReportButton: function() {
        return this.matchFinished && ['home', 'away'].every( side => this.isComplaintOk(side) ) && ['referee1', 'referee2', 'referee3'].every( ref => !this.referees[ref] || this.referees[ref].status == 'missing' || this.hasPersonSigned(['referees', ref]) );
      },
      isComplaintOk: function(side) {
        return this.complaint[side].isFiled == 'no' || this.hasPersonSigned(['complaint', side, 'captain']);
      },
      setPage: function(page) {
        setContext("logTable.page", page);
      },
      isLogFeasible: function(log) {
        return log && this.doFiltersApply( log );
      },
      isLogTablePageSelected: function(page) {
        return this.logTable.page === page;
      },
      showLog: function(i) {
        var log = this.reversedLog(i);
        if(!this.isLogFeasible(log)) return false;

        var index = -1;
        for(var i = 0; i < this.logs.length; i++) {
          var l = this.reversedLog(i);
          if(this.isLogFeasible(l)) {
            index++;
          }

          if(l == log) break;
        }
        return (this.logTable.page-1)*this.logTable.limit <= index && index < this.logTable.page*this.logTable.limit;
      },
      clearLogFilters: function() {
        for(filter in this.logTable.filters) {
          this.logTable.filters[filter] = "";
        }
        refreshContext();
      },
      getLogTableMaxPage: function() {
        return Math.ceil(this.getFeasibleLogCount() / this.logTable.limit);
      },
      getFeasibleLogCount: function() {
        var con = this;
        return this.logs.filter(function(log){ return con.isLogFeasible(log); }).length;
      },
      isLogTablePageVisible: function(page) {
        return page <= this.getLogTableMaxPage();
      },
      isLogEdited: function(i) {
        var log = this.reversedLog(i);
        return log && log.editedById;
      },
      logArrayQuarter: function(i) {
        var log = this.reversedLog(i);
        return log ? scoresheet.quarterName(log.quarter) : "";
      },
      logArrayMinute: function(i) {
        var log = this.reversedLog(i);
        return log ? log.minute + "'" : "";
      },
      logArrayAction: function(i) {
        var log = this.reversedLog(i);
        return log ? this.logAction(log) : "";
      },
      logArrayTeam: function(i) {
        var log = this.reversedLog(i);
        if(log && log.type) {
          return scoresheet.data.side2type2data[log.type].name;
        } else {
          return "";
        }
      },
      logArrayNumber: function(i) {
        var log = this.reversedLog(i);
        if(log && log.pid) {
          return scoresheet.player(log.pid).number;
        } else {
          return "";
        }
      },
      logArrayEntity: function(i) {
        var log = this.reversedLog(i);
        if(log) { 
          if(log.pid) {
            return scoresheet.playerName(log.pid);
          } else if([scoresheet.data.COACH, scoresheet.data.ASSISTANT, scoresheet.data.OTHER].indexOf(log.staffType) >= 0) {
            return scoresheet.data.logs[log.staffType];
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      editActionText: function() {
        return this.edit &&  this.edit.log ? this.logAction(this.edit.log) : "";
      },
      logAction: function(log) {
        return scoresheet.data.logs.actions[log.fn].name(log);
      },
      logActionText: function(log) {
        return scoresheet.data.logs.actions[log.fn].text(log);
      },
      removeLog: function(i) {
        var log = this.reversedLog(i);
        var con = this;
        alerts.confirmation({ type: 'warning', title: 'POTRDITEV', body: '<p>Ali res želiš izbrisati izbran dogodek?<p></p>' + this.logActionText(log) + '</p>', yesCallback: function() {
          log.editedById = "DELETED";
          con.rebuildFromLogs();
          scoresheet.save();
        }});
      },
      editLog: function(i) {
        var log = this.reversedLog(i);
        this.edit = {
          log: log,
          changedLog: $.extend({}, log, { id: this.nextId(), note: "" }),
          index: this.logIndexById(log.id)
        };
        scoresheet.buildEditSelects();
        refreshContext();
        this.openEditLogModal();
      },
      insertLogBefore: function(i) {
        var log = this.reversedLog(i);
        this.new = {
          newLog: $.extend({}, { id: this.nextId(), note: "" }),
          index: this.logIndexById(log.id)
        };
        scoresheet.buildNewSelects();
        refreshContext();
        this.openNewLogModal();
      },
      openEditLogModal: function() {
        $('#edit-log-modal').modal('show');
      },
      closeEditLogModal: function() {
        $('#edit-log-modal').modal('hide');
      },
      openNewLogModal: function() {
        $('#new-log-modal').modal('show');
      },
      closeNewLogModal: function() {
        $('#new-log-modal').modal('hide');
      },
      openFtModal: function() {
        $('#ft-modal').modal('show');
      },
      closeFtModal: function(reset) {
        $('#ft-modal').modal('hide');
        if(reset) {        
          this.ft = null;
          this.reset();
        }
      },
      canRemoveLog: function(i) {
        var log = this.reversedLog(i);
        return log && !this.isLogEdited(i);
      },
      canEditLog: function(i) {
        var log = this.reversedLog(i);
        return log && !this.isLogEdited(i) && ["changeStarting5", "changeCaptain", "setFirstOffense", "changePlayed", "changeScore", "removeTimeout", "changeTimeouts", "changeChallenge"].concat(scoresheet.changeFoulsActions()).indexOf(log.fn) >= 0;
      },
      getValue: function(val) {
        var intVal = parseInt(val);
        if(val && !isNaN(intVal)) {
          return intVal;
        } else if(val == 'true') {
          return true;
        } else if(val == 'false') {
          return false;
        } else {
          return val;
        }
      },
      saveEditLog: function() {
        var l = {};
        for(key in this.edit.changedLog) {
          if(key == 'inverseArgs') continue;

          var val = this.edit.changedLog[key];
          if(val == null || val == undefined) continue;

          l[key] = this.getValue(val);
        }
        this.updateOriginalLog(this.edit.log);
        this.logs.splice(this.edit.index+1, 0, l);
        this.edit = {};

        this.rebuildFromLogs();
        scoresheet.save();
        this.closeEditLogModal();
      },
      prepareNewLog: function() {
        var l = {};
        for(key in this.new.newLog) {
          var val = this.new.newLog[key];
          if(val == null || val == undefined) continue;

          if(key == 'fn') {
            var tmp = val.split('|');
            if(tmp.length == 1) {
              l[key] = this.getValue(val);
            } else {
              l[key] = this.getValue(tmp[0]);
              l.diff = this.getValue(tmp[1]);
            }
          } else {
            l[key] = this.getValue(val);
          }
        }
        if(l.diff != 0 && !l.diff) {
          l.diff = 1;
        }
        if(l.benchValue) {
          l.coachIndex = this.coachIndex(l.type);
          l.mark = l.benchValue == scoresheet.data.COACH ? 'C' : 'B';
          l.benchIndex = this.benchIndex(l.type, l.benchValue);
          l.staffType = l.fn == 'changeBenchTFouls' ? scoresheet.data.COACH : l.benchValue;
        }
        return l;
      },
      isNewLogValid: function() {
        var log = this.prepareNewLog();
        var valid = log.fn && log.quarter && log.minute && (!this.actionNeedsType(log.fn) || log.type) && (!this.actionNeedsPid(log.fn) || log.pid) && (!this.isFreeThrow(log) || log.ftType) && (!this.actionNeedsCoachOrBench(log.fn) || log.benchValue);
        return valid;
      },
      saveNewLog: function() {
        this.logs.splice(this.new.index, 0, this.prepareNewLog());
        this.new = {};

        this.rebuildFromLogs();
        scoresheet.save();
        this.closeNewLogModal();
      },
      updateOriginalLog: function(log) {
        log.editedById = this.edit.changedLog.id;
        if(log.fn == 'changeCaptain') {
          this[log.pid].isCaptain = false;
        }
      },
      actionNeedsPid: function(fn) {
        if(!fn) return false;
        var action = fn.split("|")[0];
        return ["changeScore", "cancelFt", "changePlayed", "changeStarting5"].concat(scoresheet.changeFoulsActions()).indexOf(action) >= 0;
      },
      actionNeedsType: function(fn) {
        if(!fn) return false;
        var action = fn.split("|")[0];
        return ["changeQuarter"].indexOf(action) < 0;
      },
      actionNeedsCoachOrBench: function(fn) {
        if(!fn) return false;
        var action = fn.split("|")[0];
        return ['changeBenchTFouls', 'changeBenchEjections'].indexOf(action) >= 0;
      },
      showEditPlayers: function(type) {
        return this.edit && this.edit.changedLog && this.edit.changedLog.pid && this.edit.changedLog.type == type;
      },
      showNewPlayers: function(type) {
        return this.new && this.new.newLog && this.new.newLog.type == type && this.actionNeedsPid(this.new.newLog.fn);
      },
      showNewCoachAndBench: function(type) {
        return this.new && this.new.newLog && this.new.newLog.type == type && this.actionNeedsCoachOrBench(this.new.newLog.fn);
      },
      showEditFtType: function() {
        return this.edit && this.edit.changedLog && this.edit.changedLog.ftType;
      },
      showNewFtType: function() {
        if(this.new && this.new.newLog && this.new.newLog.fn) {
          var tmp = this.new.newLog.fn.split('|');
          return this.isFreeThrow({ fn: tmp[0], diff: tmp.length > 1 ? tmp[1] : null });
        } else {
          return false;
        }
      },
      showNewType: function() {
        return this.new && this.new.newLog && this.actionNeedsType(this.new.newLog.fn);
      },
      canEditFn: function() {
        return this.edit && this.edit.log && ["changeScore"].concat(scoresheet.changeFoulsActions()).indexOf(this.edit.log.fn) >= 0;
      },
      isEditChange23Score: function() {
        return this.edit && this.edit.log && this.edit.log.fn == "changeScore" && [2,3].indexOf(this.edit.log.diff) >= 0;
      },
      isEditChangeFt: function() {
        return this.edit && this.edit.log && this.edit.log.fn == "changeScore" && [0,1].indexOf(this.edit.log.diff) >= 0;
      },
      isEditChangeFouls: function() {
        return this.edit && this.edit.log && scoresheet.changeFoulsActions().indexOf(this.edit.log.fn) >= 0;
      },
      isFoul: function(log) {
        return this.isSpecialFoul(log) || ['changeFouls', 'changeFTFouls'].indexOf(log.fn) >= 0;
      },
      isSpecialFoul: function(log) {
        return this.isTechnicalFoul(log) || this.isOtherSpecialFoul(log);
      },
      isTechnicalFoul: function(log) {
        return ['changeTFouls', 'changeBenchTFouls'].indexOf(log.fn) >= 0;
      },
      isOtherSpecialFoul: function(log) {
        return ['changeUFouls', 'changeEjections', 'changeBenchEjections', 'changeFights'].indexOf(log.fn) >= 0;
      },
      isFreeThrow: function(log) {
        return (log.fn == 'changeScore' && log.diff <= 1) || log.fn == 'cancelFt';
      },
      isMinuteVisible: function(i) {
        return i <= scoresheet.data.minutesPerOT || !this.isOT();
      },
      specialFouls: function() {
        var specials = [];
        for(var i = this.logs.length - 1; i >= 0; i--) {
          var log = this.logs[i];
          if(this.isFreeThrow(log)) {
            break;
          }
          if(this.isSpecialFoul(log) && !log.undoPrevious) {
            specials.splice(0, 0, log);
          }
        }
        return specials;
      },
      haveSamePenalty: function(log1, log2) {
        return log1.minute == log2.minute && log1.quarter == log2.quarter && log1.type != log2.type && ( ( this.isTechnicalFoul(log1) && this.isTechnicalFoul(log2) ) || ( this.isOtherSpecialFoul(log1) && this.isOtherSpecialFoul(log2) ) );
      },
      logById: function(id) {
        for(var i = this.logs.length-1; i >= 0; i--) {
          var log = this.logs[i];
          if(log.id == id) return log;
        }
        return null;
      },
      logIndexById: function(id) {
        for(var i = this.logs.length-1; i >= 0; i--) {
          var log = this.logs[i];
          if(log.id == id) return i;
        }
        return null;
      },
      undoCancelledFouls: function(log) {
        if(log.cancelledWithId) {
          var log2 = this.logById(log.cancelledWithId);
          log2.cancelledWithId = null;
          log.cancelledWithId = null;
        }
      },
      checkCancelledFouls: function() {
        var specials = this.specialFouls();
        if(specials.length == 0) return;

        var i = specials.length - 1;
        var lastSpecial = specials[i--];
        var special;

        while(i >= 0) {
          special = specials[i--];
          if(!special.cancelledWithId && this.haveSamePenalty(lastSpecial, special)) {
            var txt = "<p><b>Ali se kazni za naslednji napaki izničita?</b></p>";
            txt += "<ul class='list-unstyled'>";
            txt += "<li>"+ this.logActionText(lastSpecial) +"</li>";
            txt += "<li>"+ this.logActionText(special) +"</li>";
            txt += "</ul>";
            var callback = function() {
              lastSpecial.cancelledWithId = special.id;
              special.cancelledWithId = lastSpecial.id;
              refreshContext()
              scoresheet.save();
            };
            alerts.confirmation({ title: "Izničenje kazni", body: txt, yesCallback: callback });
            break;
          }
        }
      },
      setBonus: function(type, isBonus, quarter) {
        var qkey = quarter >= 4 ? 'q4' : 'q' + quarter;
        setData(type+".bonus."+qkey, isBonus);
      },
      changeFoulsAction: function(con, type, diff, pid, quarter, undo) {
          var tkey = type+".fouls.q"+quarter;
          var n = getContext(tkey)+diff;
          setData(tkey, n);

          var pkey = pid+".fouls";
          setData(pkey, getContext(pkey)+diff);
          con.updatePlayed(pid);

          if(!undo) {
            con.setBonus(type, n >= scoresheet.data.bonus, quarter);
          }
          return n;
      },
      changeFouls: function(type, diff, pid, undo) {
        if(!undo && (!this.validateNextOffense() || !this.validateMatchFinished() || !this.validateEligible(pid))) return;  //  || !this.validateFouledOut(pid)

        var con = this; // we have to save the context
        this.waitingForMinute = function() {
          
          var n = con.changeFoulsAction(con, type, diff, pid, con.quarter, undo);

          if(undo) {
            con.setBonus(type, n >= scoresheet.data.bonus, con.quarter);
          } else {
            con.addChangeLog("changeFouls", type, pid, diff);
            con.checkEligibleToContinue(type, pid);
            if(n == scoresheet.data.bonus) {
              var callback = function() {
                con.setBonus(type, true, con.quarter);
                scoresheet.save();
              };
              alerts.confirmation({ title: "POZOR", body: "<p>Ekipa je izkoristila bonus " + n + " osebnih napak. Klikni nadaljuj.</p>", continueCallback: callback });
            }
          }
          refreshContext();
        };
      },
      changeFTFoulsAction: function(con, type, diff, pid, quarter, undo) {
        var tkey = type+".fouls.q"+quarter;
        var n = getContext(tkey)+diff;
        setData(tkey, n);

        con.setBonus(type, n >= scoresheet.data.bonus, quarter);

        var pkey = pid+".fouls"
        var foulsCount = getContext(pkey);

        var typekey = pid+".foulTypes";
        if(undo) {
          getContext(typekey)[foulsCount-1] = "";
        } else {
          getContext(typekey)[foulsCount] = "|";
        }

        setData(pkey, foulsCount+diff);
        con.updatePlayed(pid);
      },
      changeFTFouls: function(type, diff, pid, undo) {
        if(!undo && (!this.validateNextOffense() || !this.validateMatchFinished() || !this.validateEligible(pid))) return;  //  || !this.validateFouledOut(pid)
        
        var con = this; // we have to save the context
        this.waitingForMinute = function() {

          con.changeFTFoulsAction(con, type, diff, pid, con.quarter, undo);

          if(!undo) { 
            con.addChangeLog("changeFTFouls", type, pid, diff);
            con.checkEligibleToContinue(type, pid);
          }
          refreshContext();
        };
      },
      changeCaptainAction: function(type, pid, undo) {
        var p = this[pid];
        p.isCaptain = !undo;
        var coach = this.coach(type);
        if(this.isOriginallyOther(coach) && !this.pid) {
          if(undo) {
            coach.firstName = null;
            coach.lastName = null;
            coach.pid = null;
          } else {
            this.setCoach(coach, true, type);
          }
        }
      },
      changeCaptain: function(type, pid, undo) {
        this.changeCaptainAction(type, pid, undo);
        if(!undo) this.addChangeCaptainLog(type, pid);
        this.reset();
      },
      cancelFtAction: function(con, type, diff, pid, undo) {
        var stype = scoresheet.field(diff);
        var pkey = pid+".cft";
        var factor = undo ? -1 : 1;
        setData(pkey, getContext(pkey)+factor);
        con.updatePlayed(pid);
      },
      cancelFt: function(type, diff, pid, undo) {
        if(!undo && (!this.validateNextOffense() || !this.validateMatchFinished() || !this.validateEligible(pid))) return;  //  || !this.validateFouledOut(pid)
        var con = this; // we have to save the context
        this.waitingForMinute = function() {
          con.cancelFtAction(con, type, diff, pid, undo);
          refreshContext();
        };
      },
      changeScoreAction: function(con, type, diff, pid, quarter, undo) {
        var skey = type+".score.q"+quarter;
        setData(skey, getContext(skey)+diff);

        var stype = scoresheet.field(diff);
        var pkey = pid+"."+stype;
        var factor = diff == 0 ? (undo ? -1 : 1) : scoresheet.factor(diff);
        setData(pkey, getContext(pkey)+factor);
        con.updatePlayed(pid);
      },
      changeScore: function(type, diff, pid, undo) {
        if(!undo && (!this.validateNextOffense() || !this.validateMatchFinished() || !this.validateEligible(pid))) return;  //  || !this.validateFouledOut(pid)

        var con = this; // we have to save the context
        this.waitingForMinute = function() {
          con.changeScoreAction(con, type, diff, pid, con.quarter, undo);

          if(!undo) {
            var log = con.addChangeLog("changeScore", type, pid, diff);
          }
          refreshContext();
        };
      },
      expectedFtType: function(ftType) {
        if(ftType == 'one_of_two') {
          return 'two_of_two';
        } else if(ftType == 'one_of_three') {
          return 'two_of_three';
        } else if(ftType == 'two_of_three') {
          return 'three_of_three';
        } else {
          return null;
        }
      },
      endFreeThrowAction: function(diff) {
        this.changeScoreAction(this, this.ft.type, diff, this.ft.pid, this.quarter);
        this.addFtLog(this.ft.type, this.ft.pid, diff, this.ft.ftType);
        var eFtType = this.expectedFtType(this.ft.ftType);
        if(eFtType) {
          this.closeFtModal(false);
          var self = this;
          setTimeout(function() {
            self.handleFreeThrow(self.ft.type, self.ft.pid, eFtType);
          }, 400);
        } else {
          this.closeFtModal(true);
        }
      },
      madeFt: function() {
        this.endFreeThrowAction(1);
      },
      missedFt: function() {
        this.endFreeThrowAction(0);
      },
      cancelledFt: function() {
        this.cancelFtAction(this, this.ft.type, 0, this.ft.pid);
        this.addCancelFtLog(this.ft.type, this.ft.pid, 0, this.ft.ftType);
        this.closeFtModal(true);
      },
      selectFtType: function(ftType) {
        setContext("ft.ftType", ftType);
      },
      checkSelectedFtType: function(ftType) {
        return this.ft && this.ft.ftType == ftType;
      },
      handleFreeThrow: function(type, pid, ftType) {
        if(!this.validateNextOffense() || !this.validateMatchFinished() || !this.validateEligible(pid)) return;

        this.ft = {
          ftType: ftType,
          type: type,
          pid: pid
        };
        refreshContext();
        this.openFtModal();
      },
      maySelectTimeout: function(type) {
        return this.getTimeoutsLeft(type) > 0 && this.isFirstOffenseSet();
      },
      maySelectChallenge: function(type) {
        return this.getChallengesLeft(type) > 0 && this.isFirstOffenseSet();
      },
      isChallengeTaken: function(type) {
        return this.getChallengesLeft(type) == 0;
      },
      mayRemoveTimeout: function(type) {
        return this.quarter == 4 && this.minute >= 9 && this.getTimeoutsLeft(type) == this.getTotalTimeouts();
      },
      validateTimeoutRemoval: function(type) {
        var is = this.mayRemoveTimeout(type);
        if(!is) alerts.warning("<p>Minute odmora ni mogoče odstraniti.</p>");
        return is;
      },
      removeTimeoutAction: function(con, type, diff) {
          var tkey = type+".timeouts."+con.getHalf();
          var timeouts = getContext(tkey);
          setData(tkey, timeouts+diff);
      },
      removeTimeout: function(type, diff, pid, undo) {
        if(!undo && !this.validateTimeoutRemoval(type)) return;

        var con = this; // we have to save the context
        this.waitingForMinute = function() {
          con.removeTimeoutAction(con, type, diff);
          if(!undo) con.addChangeLog("removeTimeout", type, null, diff);
          refreshContext();
        };
        return this.waitingForMinute;
      },
      changeTimeoutsAction: function(con, type, diff, quarter) {
        var tkey = type+".timeouts."+con.getHalf(quarter);
        var timeouts = getContext(tkey);
        setData(tkey, timeouts+diff);
      },
      changeTimeouts: function(type, diff, pid, undo) {
        if(!undo && (!this.validateNextOffense() || !this.validateMatchFinished() || !this.validateTimeouts(type))) return;

        var con = this; // we have to save the context
        this.waitingForMinute = function() {
          var removeTimeout = !undo && con.mayRemoveTimeout(type);
          if(removeTimeout) {
            con.removeTimeout(type, diff, pid, undo)();
          }
          con.changeTimeoutsAction(con, type, diff);
          if(!undo) con.addChangeLog("changeTimeouts", type, null, diff, removeTimeout);
          refreshContext();
        };
      },
      changeConfirmTeamSelectionAction: function(con, type, confirmed) {
        this[type].cdConfirmed = confirmed;
      },
      // diff, pid is needed for undo only! they are never used!
      changeConfirmTeamSelection: function(type, diff, pid, undo) {
        this.changeConfirmTeamSelectionAction(this, type, !undo);
        if(!undo) this.addChangeLog("changeConfirmTeamSelection", type);
        refreshContext();
      },
      changeChallengeAction: function(con, type, diff) {
        var tkey = type+".challengeCount";
        var cc = getContext(tkey) || 0;
        setData(tkey, cc + diff);
      },
      changeChallenge: function(type, diff, pid, undo) {
        if(!undo && (!this.validateNextOffense() || !this.validateMatchFinished() || !this.validateChallenge(type))) return;

        var con = this; // we have to save the context
        this.waitingForMinute = function() {
          con.changeChallengeAction(con, type, diff);
          if(!undo) con.addChangeLog("changeChallenge", type, null, diff);
          refreshContext();
        };
      },
      changeTFoulsAction: function(con, type, diff, pid, quarter, undo) {
          var tkey = type+".fouls.q"+quarter;
          var n = getContext(tkey)+diff;
          setData(tkey, n);

          con.setBonus(type, n >= scoresheet.data.bonus, quarter);

          var pkey = pid+".fouls"
          var foulsCount = getContext(pkey);

          var typekey = pid+".foulTypes";
          if(undo) {
            getContext(typekey)[foulsCount-1] = "";
          } else {
            getContext(typekey)[foulsCount] = "T";
          }

          setData(pkey, foulsCount+diff);

          var ptkey = pid+".tfouls"
          setData(ptkey, getContext(ptkey)+diff);
          con.updatePlayed(pid);
      },
      changeTFouls: function(type, diff, pid, undo) {
        if(!undo && (!this.validateNextOffense() || !this.validateMatchFinished() || !this.validateEligible(pid))) return;  //  || !this.validateFouledOut(pid)
        
        var con = this; // we have to save the context
        this.waitingForMinute = function() {
          con.changeTFoulsAction(con, type, diff, pid, con.quarter, undo);
          if(!undo) { 
            con.addChangeLog("changeTFouls", type, pid, diff);
            con.checkEligibleToContinue(type, pid);
            con.checkCancelledFouls();
            con.validateWithoutCaptain(type);
          }
          refreshContext();
        };
      },
      changeUFoulsAction: function(con, type, diff, pid, quarter, undo) {
          var tkey = type+".fouls.q"+quarter;
          var n = getContext(tkey)+diff;
          setData(tkey, n);

          con.setBonus(type, n >= scoresheet.data.bonus, quarter);

          var pkey = pid+".fouls"
          var foulsCount = getContext(pkey);

          var typekey = pid+".foulTypes";
          if(undo) {
            getContext(typekey)[foulsCount-1] = "";
          } else {
            getContext(typekey)[foulsCount] = "U";
          }

          setData(pkey, foulsCount+diff);

          var ukey = pid+".ufouls"
          setData(ukey, getContext(ukey)+diff);
          con.updatePlayed(pid);
      },
      changeUFouls: function(type, diff, pid, undo) {
        if(!undo && (!this.validateNextOffense() || !this.validateMatchFinished() || !this.validateEligible(pid))) return;  //  || !this.validateFouledOut(pid)

        var con = this; // we have to save the context
        this.waitingForMinute = function() {
          con.changeUFoulsAction(con, type, diff, pid, con.quarter, undo);
          if(!undo) {
            con.addChangeLog("changeUFouls", type, pid, diff);
            con.checkEligibleToContinue(type, pid);
            con.checkCancelledFouls();
            con.validateWithoutCaptain(type);
          }
          refreshContext();
        };
      },
      changeFightsAction: function(con, type, diff, pid, quarter, undo) {
        // Tehnične ali izključujoče napake dosojene zaradi pretepa ne štejejo med napake moštva.

        var pkey = pid+".fouls"
        var foulsCount = getContext(pkey);

        var typekey = pid+".foulTypes";
        if(undo) {
          var foulTypes = getContext(typekey);
          for(let i = 0; i < foulTypes.length; i++) {
            if(foulTypes[i] == 'F') {
              getContext(typekey)[i] = "";
            }
          }
          setData(pkey, foulTypes.filter( t => t != "").length);
        } else {
          if(foulsCount >= 5) {
            getContext(typekey)[foulsCount] = "F";
            setData(pkey, foulsCount+1);
          } else {
            let i = foulsCount;
            while(i < 5) {
              getContext(typekey)[i] = "F";
              i++;
            }
            setData(pkey, 5);
          }
        }

        var fkey = pid+".fights"
        setData(fkey, getContext(fkey)+diff);
      },
      changeFights: function(type, diff, pid, undo) {
        if(!undo && (!this.validateNextOffense() || !this.validateMatchFinished() || !this.validateEligible(pid))) return;  //  || !this.validateFouledOut(pid)

        var con = this; // we have to save the context
        this.waitingForMinute = function() {
          con.changeFightsAction(con, type, diff, pid, con.quarter, undo);
          if(!undo) {
            con.addChangeLog("changeFights", type, pid, diff);
            con.checkEligibleToContinue(type, pid);
            // con.checkCancelledFouls();
            con.validateWithoutCaptain(type);
          }
          refreshContext();
        };
      },
      changeEjectionsAction: function(con, type, diff, pid, quarter, undo) {
        var tkey = type+".fouls.q"+quarter;
        var n = getContext(tkey)+diff;
        setData(tkey, n);

        con.setBonus(type, n >= scoresheet.data.bonus, quarter);

        var pkey = pid+".fouls"
        var foulsCount = getContext(pkey);

        var typekey = pid+".foulTypes";
        if(undo) {
          getContext(typekey)[foulsCount-1] = "";
        } else {
          getContext(typekey)[foulsCount] = "D";
        }

        setData(pkey, getContext(pkey)+diff);

        var ekey = pid+".ejections"
        setData(ekey, getContext(ekey)+diff);
        con.updatePlayed(pid);
      },
      changeEjections: function(type, diff, pid, undo) {
        if(!undo && (!this.validateNextOffense() || !this.validateMatchFinished() || !this.validateEligible(pid))) return;  //  || !this.validateFouledOut(pid)

        var con = this; // we have to save the context
        this.waitingForMinute = function() {
          con.changeEjectionsAction(con, type, diff, pid, con.quarter, undo);
          if(!undo) {
            con.addChangeLog("changeEjections", type, pid, diff);
            con.checkEligibleToContinue(type, pid);
            con.checkCancelledFouls();
            con.validateWithoutCaptain(type);
          }
          refreshContext();
        };
      },
      changeMinute: function(min) {
        setContext("minute", min);
        this.endAction();
      },
      endAction: function() {
        if(this.waitingForMinute) {
          var callbacks = [this.waitingForMinute].flat();
          for(var i = 0; i < callbacks.length; i++) {
            callbacks[i]();
          }
          this.reset();
        }
      },
      changeQuarter: function(diff, undo) {
        if(!this.validateWithoutCaptain('home') || !this.validateWithoutCaptain('away')) {
          this.reset();
          return;
        }

        if(!undo && (!this.validateNextOffense() || !this.validateMatchFinished())) return;

        var con = this;
        var yesCallback = function() {
          // diff = 1 means end quarter
          // diff = -1 means undo

          con.quarter += diff;
          if(con.quarter == 3) {
            con.halfTimePossesion = con.nextOffense;

            if(scoresheet.data.resetScoreEveryQuarter && con.showTeamSelection('home')) {
              alerts.confirmation({ 
                type: 'info',
                title: "Konec polčasa", 
                body: "<p>Pred nadaljevanjem tekme je potrebno določiti igralce za C in D pri obeh ekipah.</p>"
              });
            }
          }
          if(!undo) con.addChangeQuarterLog("changeQuarter", diff);
          setContext("minute", 1);

          // con.matchFinished = !undo && con.isOT() && con.score('home') !== con.score('away');
          // if(!con.matchFinished) {
          //   con.matchFinishedAt = null;
          // }


          if(!undo && con.isOT()) {
            if(scoresheet.data.resetScoreEveryQuarter) {
              con.finishMatch();
            } else {              
              alerts.confirmation({ 
                type: 'info',
                title: "Ali je konec tekme?", 
                body: "<p>Če je tekma končana, pritisni gumb <b>ZAKLJUČI TEKMO</b>.</p><p>Če tekma še ni končana in so potrebni podaljški, pritisni gumb <b>TEKME ŠE NI KONEC</b>.</p>",
                yesLabel: "ZAKLJUČI TEKMO",
                noLabel: "TEKME ŠE NI KONEC",
                yesCallback: con.finishMatch.bind(con)
              });
            }
          }
          scoresheet.refreshQuarterOptions();
        };
        if(undo) {
          yesCallback();
        } else {
          alerts.confirmation({ title: "ZAKLJUČEVANJE ČETRTINE", body: "<p>Ali si prepričan, da želiš zaključiti četrtino?</p>", yesCallback: yesCallback });
        }       
      },
      finishMatch: function(undo) {
        var con = this;
        if(!undo) {
          con.matchFinished = true;
          con.lastPlayedQuarter = con.quarter - 1;
          scoresheet.saveTime();
          con.addFinishMatchLog("finishMatch");
          alerts.success("Konec tekme", "<p>1. sodnik mora sedaj pregledati zapisnik. Če je potrebno narediti popravek v zapisniku, lahko to storite v tabeli dogodkov na dnu strani.</p><p>Kapetana obeh ekip imata možnost vložiti pritožbo.</p><p>Po podpisih vseh sodnikov pojdite na poročilo o tekmi.</p>");
        } else {
          con.matchFinished = false;
          con.matchFinishedAt = null;
          con.lastPlayedQuarter = null;
        }
      },
      changeBenchTFoulsUndo: function(type, diff, coachIndex, newCoachIndex, newOtherIndex, mark) {
        var con = this; // we have to save the context
        this.waitingForMinute = function() {
          // 1. add to coach's fouls (diff is negative!)
          var coach = con.bench(type, coachIndex);
          var key = mark.toLowerCase()+"fouls";
          coach[key] += diff;
          var foulTypesIndex = con.benchFouls(type, coachIndex);

          // 2. fix mark
          coach.foulTypes[foulTypesIndex] = "";

          // 3. if this was an ejecting foul, then we must set up coach and staff back appropriately
          if(newCoachIndex !== null) {
            con.setCoach(coach);

            var exCoach = con.bench(type, newCoachIndex);
            var exOther = con.bench(type, newOtherIndex);
            con.setAssistantAndOtherRoles(exCoach, exOther);
          }
        };
      },
      changeBenchTFoulsAction: function(con, type, diff, coachIndex, mark) {
        // 1. add to coach's fouls
        var coach = con.bench(type, coachIndex);
        var key = mark.toLowerCase()+"fouls";
        var foulTypesIndex = con.benchFouls(type, coachIndex);
        coach[key] += diff;

        // 2. add appropriate mark
        coach.foulTypes[foulTypesIndex] = mark;

        // 3. if this was an ejection foul for coach, coach must be relased of the duties, and new one assigned; same for staff!      
        var newCoachIndex = null;
        var newOtherIndex = null;
        if(con.isBenchEjected(type, coachIndex)) {
          // with the new foul coach is ejected!
          con.setRoleNull(coach);

          newCoachIndex = con.nextEligibleBenchIndex(type,coachIndex+1);
          var newCoach = con.bench(type, newCoachIndex);
          con.setCoach(newCoach, con.isOriginallyOther(newCoach), type);

          newOtherIndex = con.nextEligibleBenchIndex(type,newCoachIndex+1);
          var newOther = con.bench(type, newOtherIndex);
          con.setOther(newOther);
        }
        return { newCoachIndex: newCoachIndex, newOtherIndex: newOtherIndex };
      },
      changeBenchTFoulsCallback: function(type, diff, coachIndex, mark, undoPrevious) {
        var con = this; // we have to save the context
        return function() {
          // 1., 2., 3. handle data
          var d = con.changeBenchTFoulsAction(con, type, diff, coachIndex, mark);

          // 4. add to log
          con.addChangeBenchLog("changeBenchTFouls", type, diff, coachIndex, d.newCoachIndex, d.newOtherIndex, mark, scoresheet.data.COACH, undoPrevious);

          // 5. throw alert if necessary
          con.checkBenchEligibleToContinue(type, coachIndex);

          // 6. check if technical might be cancelled with another one on the other side
          if(!undoPrevious) con.checkCancelledFouls();

          // 7. check if team is without captain
          con.validateWithoutCaptain(type);
        };
      },
      changeBenchTFouls: function(type, diff, coachIndex, mark) {    // mark: C or B; always coach! even if staff gets technical!
        if(!this.validateNextOffense() || !this.validateMatchFinished() || !this.validateBenchEligible(type, coachIndex)) return;
        this.waitingForMinute = this.changeBenchTFoulsCallback(type, diff, coachIndex, mark);
      },
      changeBenchEjectionsUndo: function(type, diff, benchIndex, newBenchIndex, newNewBenchIndex, mark) {
        var con = this; // we have to save the context
        this.waitingForMinute = function() {
          // 1. add to coach's fouls (diff is negative!)
          var bench = con.bench(type, benchIndex);
          var key = "ejections";
          bench[key] += diff;
          var foulTypesIndex = con.benchFouls(type, benchIndex);

          // 2. fix mark
          bench.foulTypes[foulTypesIndex] = "";

          if(con.isPreviousOther(bench)) {
            con.setOther(bench);

            var exOther = con.bench(type, newBenchIndex);
            con.setRoleNull(exOther);
          } else if(con.isPreviousAssistant(bench)) {
            con.setAssistant(bench);
          } else {
            con.setCoach(bench);

            var exCoach = con.bench(type, newBenchIndex);
            var exOther = con.bench(type, newNewBenchIndex);
            con.setAssistantAndOtherRoles(exCoach, exOther);
          }
        };
      },
      changeBenchEjectionsAction: function(con, type, diff, benchIndex) {
        // 1. change ejections
        var bench = con.bench(type, benchIndex);
        var key = "ejections";
        var foulTypesIndex = con.benchFouls(type, benchIndex);
        bench[key] += diff;

        // 2. add appropriate mark
        bench.foulTypes[foulTypesIndex] = "D";
        var role = bench.role;

        // 3. change roles
        var newCoachIndex = null;
        var newOtherIndex = null;

        if(con.isCoach(type, benchIndex)) {
          con.setRoleNull(bench);
          newCoachIndex = con.nextEligibleBenchIndex(type, benchIndex+1);
          var newCoach = con.bench(type, newCoachIndex);
          con.setCoach(newCoach, con.isOriginallyOther(newCoach), type);
          
          // here new other may be the same as current other
          newOtherIndex = con.nextEligibleBenchIndex(type, newCoachIndex+1);
          var newOther = con.bench(type, newOtherIndex);
          con.setOther(newOther);
        } else if(con.isOther(bench)) {
          con.setRoleNull(bench);
          newCoachIndex = con.nextEligibleBenchIndex(type, benchIndex+1);
          var newOther = con.bench(type, newCoachIndex);
          con.setOther(newOther);
        } else if(con.isAssistant(bench)) {
          con.setRoleNull(bench);
        }
        return { newCoachIndex: newCoachIndex, newOtherIndex: newOtherIndex, role: role };
      },
      changeBenchEjections: function(type, diff, benchIndex) {   // stype = coach
        if(!this.validateNextOffense() || !this.validateMatchFinished() || !this.validateBenchEligible(type, benchIndex)) return;

        var con = this; // we have to save the context
        this.waitingForMinute = [function() {
          var d = con.changeBenchEjectionsAction(con, type, diff, benchIndex);

          con.addChangeBenchLog("changeBenchEjections", type, diff, benchIndex, d.newCoachIndex, d.newOtherIndex, "D", d.role);
          con.checkBenchEligibleToContinue(type, benchIndex);

          con.checkCancelledFouls();
          con.validateWithoutCaptain(type);
        }];

        if(!con.isCoach(type, benchIndex)) {
          // if b is not coach, then we must add another B to coach
          var coachIndex = this.coachIndex(type);
          this.waitingForMinute.push( this.changeBenchTFoulsCallback(type, 1, coachIndex, "B", true) );
        }
      },
      changeStarting5Action: function(pid, starting5) {
        var key = pid+".isStarting";
        setData(key, starting5);
        this.updatePlayed(pid);
      },
      changeStarting5: function(pid, starting5, undo) {
        this.changeStarting5Action(pid, starting5);
        if(!undo) this.addChangeStarting5Log(pid, starting5);
        refreshContext();
      },
      changePlayedAction: function(pid) {
        var key = pid+".playedSelected";
        setData(key, !getContext(key))
        this.updatePlayed(pid);
      },
      changePlayed: function(pid, undo) {
        if(!this.validateWithoutCaptain('home') || !this.validateWithoutCaptain('away')) {
          this.reset();
          return;
        }
        if(!undo && this.hasPlayed(pid)) return;
        if(!undo && (!this.validateMatchFinished() || !this.validateEligible(pid) || !this.validateFouledOut(pid))) return;

        this.changePlayedAction(pid);

        if(!undo) this.addChangePlayedLog(pid);
        refreshContext();
      },
      setState: function(newState) {
        this.previousPreviousState = this.previousState;
        this.previousState = this.state;
        setContext("state", newState);
      },
      undoSetState: function() {
        this.state = this.previousState;
        this.previousState = this.previousPreviousState;
        setContext("previousPreviousState", null);
      },
      getHalf: function(quarter) {
        quarter = quarter || this.quarter;
        if(quarter <= 2) {
          return "h1";
        } else if(quarter <= 4) {
          return "h2";
        } else {
          return "q" + quarter;
        }
      },
      isTimeoutAvailable: function(type, i) {
        return i <= this.getTotalTimeouts() && !this.isTimeoutTaken(type, i);
      },
      isTimeoutTaken: function(type, i) {
        return this[type].timeouts[this.getHalf()] >= i;
      },
      getTotalTimeouts: function() {
        return scoresheet.data.timeouts[this.getHalf()];
      },
      getTimeoutsLeft: function(type) {
        return this.getTotalTimeouts() - this[type].timeouts[this.getHalf()];
      },
      getChallengesLeft: function(type) {
        return scoresheet.data.challengeCount - (this[type].challengeCount || 0);
      },
      selectChallenge: function(type) {
        if(!this.validateWithoutCaptain('home') || !this.validateWithoutCaptain('away')) {
          this.reset();
          return;
        }
        
        this.reset();
        this.setState(scoresheet.data.state.CHALLENGE_TAKEN);
        this.changeChallenge(type, 1);        
      },
      selectTimeout: function(type) {
        if(!this.validateWithoutCaptain('home') || !this.validateWithoutCaptain('away')) {
          this.reset();
          return;
        }

        this.reset();
        this.setState(scoresheet.data.state.TIMEOUT_TAKEN);
        this.changeTimeouts(type, 1);
      },
      selectTimeoutRemoval: function(type) {
        if(!this.validateWithoutCaptain('home') || !this.validateWithoutCaptain('away')) {
          this.reset();
          return;
        }

        this.reset();
        this.setState(scoresheet.data.state.REMOVE_TIMEOUT);
        this.removeTimeout(type, 1);
      },
      selectAction: function(newState) {
        if(!this.validateWithoutCaptain('home') || !this.validateWithoutCaptain('away')) {
          this.reset();
          return;
        }

        this.setState(newState);
        if(this.previousState === scoresheet.data.state.PLAYER_SELECTED && this.playerId !== null) {
          var pid = this.playerId;
          var type = scoresheet.player(pid).type;
          if(newState === scoresheet.data.state.FT_PRESSED) {
            this.handleFreeThrow(type, pid);
            // this.changeScore(type, 1, pid);
          } else if(newState == scoresheet.data.state.PT2_PRESSED) {
            this.changeScore(type, 2, pid);
          } else if(newState == scoresheet.data.state.PT3_PRESSED) {
            this.changeScore(type, 3, pid);
          } else if(newState == scoresheet.data.state.FOUL_PRESSED) {
            this.changeFouls(type, 1, pid);
          } else if(newState == scoresheet.data.state.FTFOUL_PRESSED) {
            this.changeFTFouls(type, 1, pid);
          } else if(newState == scoresheet.data.state.TFOUL_PRESSED) {
            this.changeTFouls(type, 1, pid);
          } else if(newState == scoresheet.data.state.UFOUL_PRESSED) {
            this.changeUFouls(type, 1, pid);
          } else if(newState == scoresheet.data.state.EJECTION_PRESSED) {
            this.changeEjections(type, 1, pid);
          } else if(newState == scoresheet.data.state.FIGHT_PRESSED) {
            this.changeFights(type, 1, pid);
          }
        } else if([scoresheet.data.state.COACH_SELECTED, scoresheet.data.state.ASSISTANT_SELECTED, scoresheet.data.state.OTHER_SELECTED].indexOf(this.previousState) >= 0 && this.ttype !== null) {
          if(newState == scoresheet.data.state.TFOUL_PRESSED) {
            this.changeBenchTFouls(this.ttype, 1, this.coachIndex(this.ttype), this.isCoach(this.ttype, this.selectedBenchIndex) ? 'C' : 'B');
          } else if(newState == scoresheet.data.state.EJECTION_PRESSED) {
            this.changeBenchEjections(this.ttype, 1, this.selectedBenchIndex);
          } else {
            alerts.warning("<p>Edina možna dogodka sta: tehnična napaka ali izključitev!</p>");
            this.undoSetState()
          }
        } else {
          this.playerId = null;
          this.ttype = null;
          this.selectedBenchIndex = null;
          refreshContext();
        }
      },
      selectPlayer: function(type, pid) {
        if(this.state === scoresheet.data.state.CHANGE_CAPTAIN) {
          if(type != this.ttype) {
            alerts.warning("<p>Izberi igralca " + (this.ttype == 'home' ? 'domače' : 'gostujoče') + " ekipe!</p>");
          } else {
            this.changeCaptain(type, pid);
          }
        } else {
          if(!this.validateWithoutCaptain('home') || !this.validateWithoutCaptain('away')) {
            this.reset();
            return;
          }

          this.selectedBenchIndex = null;
          setContext("playerId", pid);
          this.setState(scoresheet.data.state.PLAYER_SELECTED);
          
          if(this.previousState == scoresheet.data.state.FT_PRESSED) {
            this.handleFreeThrow(type, pid);
          } else if(this.previousState == scoresheet.data.state.PT2_PRESSED) {
            this.changeScore(type, 2, pid);
          } else if(this.previousState == scoresheet.data.state.PT3_PRESSED) {
            this.changeScore(type, 3, pid);
          } else if(this.previousState == scoresheet.data.state.FOUL_PRESSED) {
            this.changeFouls(type, 1, pid);
          } else if(this.previousState == scoresheet.data.state.FTFOUL_PRESSED) {
            this.changeFTFouls(type, 1, pid);
          } else if(this.previousState == scoresheet.data.state.TFOUL_PRESSED) {
            this.changeTFouls(type, 1, pid);
          } else if(this.previousState == scoresheet.data.state.UFOUL_PRESSED) {
            this.changeUFouls(type, 1, pid);
          } else if(this.previousState == scoresheet.data.state.EJECTION_PRESSED) {
            this.changeEjections(type, 1, pid);
          } else if(this.previousState == scoresheet.data.state.FIGHT_PRESSED) {
            this.changeFights(type, 1, pid);
          }
        }
      },
      selectBench: function(type, i) {
        if(!this.validateWithoutCaptain('home') || !this.validateWithoutCaptain('away')) {
          this.reset();
          return;
        }

        this.playerId = null;
        this.selectedBenchIndex = i;
        setContext("ttype", type);
        var isCoach = this.isCoach(type, i);
        if(isCoach) {
          this.setState(scoresheet.data.state.COACH_SELECTED);
        } else if(this.isBenchAssistant(type, i)) {
          this.setState(scoresheet.data.state.ASSISTANT_SELECTED);
        } else if(this.isBenchOther(type, i)) {
          this.setState(scoresheet.data.state.OTHER_SELECTED);
        }
        
        if(this.previousState == scoresheet.data.state.TFOUL_PRESSED) {
          this.changeBenchTFouls(type, 1, this.coachIndex(type), isCoach ? 'C' : 'B');
        } else if(this.previousState == scoresheet.data.state.EJECTION_PRESSED) {
          this.changeBenchEjections(this.ttype, 1, this.selectedBenchIndex);
        } else if(this.isActionState(this.previousState)) {
          alerts.warning("<p>Edina možna dogodka sta: tehnična napaka ali izključitev!</p>");
          this.undoSetState();
        }
      },
      isFirstOffenseSet: function() {
        return this.firstOffense !== null;
      },
      setFirstOffenseAction: function(type, undo) {
        setData("nextOffense", this.otherType(type));
        setData("firstOffense", type);
      },
      setFirstOffense: function(type, undo) {
        this.setFirstOffenseAction(type);
        this.reset();
        if(!undo) this.addFirstOffenseLog(type);
      },
      hasNextOffense: function(direction) {
        return this[direction] == this.nextOffense;
      },
      otherType: function(type) {
        if(type) {
          return type == 'home' ? 'away' : 'home';
        } else {
          return null;
        }
      },
      changeOffenseAction: function() {
        var newType = this.otherType( this.nextOffense );
        setData("nextOffense", newType);
        return newType;
      },
      changeOffense: function(undo) {
        if(!undo && !this.validateMatchFinished()) return;
        var newType = this.changeOffenseAction();
        this.reset();
        if(!undo) this.addNextOffenseLog(newType);
      },
      showSide: function(side, type) {
        return this[side] === type;
      },
      showPlayersAndStaff: function(type) {
        return !this.showTeamSelection(type);
      },
      showTeamSelection: function(type) {
        return scoresheet.data.resetScoreEveryQuarter && !this[type].cdConfirmed && this.quarter >= 3;
      },
      numberOfPlayersInC: function(type) {
        return this.numberOfPlayerForField(type, 'in_c');
      },
      numberOfPlayersInD: function(type) {
        return this.numberOfPlayerForField(type, 'in_d');
      },
      mayConfirmTeamSelection: function(type) {
        return this.numberOfPlayersInC(type) >= 4 && this.numberOfPlayersInD(type) >= 4;
      },
      numberOfPlayerForField(type, fn) {
        var n = 0;
        for(var pid of (Object.keys(scoresheet.data.playerId2data) || []) ) {
          var p = this[pid] || {};
          if(scoresheet.playerType(pid) == type && p[fn]) {
            n++;
          }
        }
        return n;
      },
      reset: function() {
        this.selectedBenchIndex = null;
        this.ttype = null;
        this.playerId = null;
        this.waitingForMinute = null;
        this.previousState = null;
        this.state = null;
        refreshContext();

        for(type of ['home', 'away']) {
          if(!this.validateWithoutCaptain(type, true)) {
            this.ttype = type;
            this.setState(scoresheet.data.state.CHANGE_CAPTAIN);
          }
        }
      },
      showInternetProblemError: function() {
        let obj = scoresheet.getFromLocalStorage();
        return !this.saving && obj && obj.scoresheetObj && obj.scoresheetObj.localStorageSaveTime && obj.scoresheetObj.serverSaveTime && obj.scoresheetObj.localStorageSaveTime - obj.scoresheetObj.serverSaveTime > 5*60*1000;
      },
      updateColors: function() {
        scoresheet.updateColors(this.home.color, this.away.color);
      },
      toggleShowPlayerNames: function() {
        setContext('showPlayerNames', this.showPlayerNames === false || this.showPlayerNames === 'false');
      },
      switchSides: function() {
        var exLeft = this.left;
        this.left = this.right;
        setContext("right", exLeft);
        scoresheet.save();
      },
      shareScoresheet: function() {
        var yesCallback = function() {
          scoresheet.invite( $('#share_email').val() );
        };
        alerts.confirmation({ type: 'info', title: 'Deli povezavo do zapisnika', body: '<p>V spodnje polje vpišite elektronski naslov osebe, komur želite poslati povezavo za spremljanje digitalnega zapisnika v živo.</p><p><input class="form-control" type="email" name="share_email" id="share_email" value="" placeholder="E-mail" /></p>', yesLabel: 'Pošlji', noLabel: 'Prekliči', yesCallback: yesCallback });
      },
      toggleFullscreen: function(forceFullScreen) {
        if(this.getFullscreenElement()) {
           document.exitFullscreen();
           setContext('fullScreen', false);
        } else {
          document.documentElement.requestFullscreen().catch(console.log);
          setContext('fullScreen', true);
        }
      },
      getFullscreenElement: function() {
        return document.fullscreenElement   //standard property
          || document.webkitFullscreenElement //safari/opera support
          || document.mozFullscreenElement    //firefox support
          || document.msFullscreenElement;    //ie/edge support
      },
      updatePlayed: function(pid, refresh) {
        var p = this[pid];
        setData(pid+".played", p.playedSelected || p.isStarting || p.cft > 0 || p.mft > 0 || p.ft > 0 || p.pt2 > 0 || p.pt3 > 0 || p.fouls > 0);
        if(refresh) {
          refreshContext();    
        }
      },
      save: function() {
        scoresheet.save();
      },
      forceSave: function() {
        this.rebuildFromLogs();
        scoresheet.save();
      },
      maySave: function() {
        return true;
        // let obj = scoresheet.getFromLocalStorage();
        // return obj && obj.scoresheetObj && obj.scoresheetObj.localStorageSaveTime && obj.scoresheetObj.serverSaveTime && obj.scoresheetObj.localStorageSaveTime - obj.scoresheetObj.serverSaveTime > 60*1000;
      },
      rebuildFromLogs: function() {
        scoresheet.setDefaults();
        for(log of this.logs) {
          if(log.editedById) continue;

          switch(log.fn) {
            case "changeStarting5":
              this.changeStarting5Action(log.pid, log.starting5);
              break;
            case "changeCaptain":
              this.changeCaptainAction(log.type, log.pid);
              break;
            case "setFirstOffense":
              this.setFirstOffenseAction(log.type);
              break;
            case "changeOffense":
              this.changeOffenseAction();
              break;
            case "changePlayed":
              this.changePlayedAction(log.pid);
              break;
            case "changeFouls":
              this.changeFoulsAction(this, log.type, log.diff, log.pid, log.quarter);
              break;
            case "changeFTFouls":
              this.changeFTFoulsAction(this, log.type, log.diff, log.pid, log.quarter);
              break;
            case "changeScore":
              this.changeScoreAction(this, log.type, log.diff, log.pid, log.quarter);
              break;
            case "changeTFouls":
              this.changeTFoulsAction(this, log.type, log.diff, log.pid, log.quarter);
              break;
            case "changeUFouls":
              this.changeUFoulsAction(this, log.type, log.diff, log.pid, log.quarter);
              break;
            case "changeEjections":
              this.changeEjectionsAction(this, log.type, log.diff, log.pid, log.quarter);
              break;
            case "changeFights":
              this.changeFightsAction(this, log.type, log.diff, log.pid, log.quarter);
              break;
            case "removeTimeout":
              this.removeTimeoutAction(this, log.type, log.diff);
              break;
            case "changeTimeouts":
              this.changeTimeoutsAction(this, log.type, log.diff, log.quarter);
              break;
            case "changeConfirmTeamSelection":
              this.changeConfirmTeamSelectionAction(this, log.type, true);
              break;
            case "changeChallenge":
              this.changeChallengeAction(this, log.type, log.diff);
              break;
            case "cancelFt":
              this.cancelFtAction(this, log.type, log.diff, log.pid);
              break;
            case "changeQuarter":
              break;
            case "changeBenchTFouls":
              this.changeBenchTFoulsAction(this, log.type, log.diff, log.coachIndex, log.mark);
              break;
            case "changeBenchEjections":
              this.changeBenchEjectionsAction(this, log.type, log.diff, log.coachIndex);
              break;
            case "finishMatch":
              break;
          }
        }
        refreshContext();
      }
    }, scoresheetObj, { state: null, playerId: null, fullScreen: false });

    // UPDATE COLORS AND PLAYED!
    context.updateColors();
    var shouldAddStarters = context.logs.length == 0;
    var sortedPlayers = scoresheet.sortByTypeAndNumber(scoresheet.data.playerId2data);
    for(pdata of sortedPlayers) {
      var pid = pdata.id;
      if(shouldAddStarters && pdata.is_starting) {
        context[pid].isStarting = true;
        context.addChangeStarting5Log(pid, true);
      }
      context.updatePlayed(pid);
    }

    // COLOR PICKER!
    $('select.colorpicker').simplecolorpicker({picker: true, theme: 'fontawesome'}).on('change', function() {
      var value = $(this).val();
      setContext($(this).data('type') + ".color." + $(this).data('colortype'), value);

      scoresheet.save();
      context.updateColors();
    });
    refreshContext();

    // BUILD FILTERS!
    scoresheet.buildFilters();

    // KEYS! TODO
    $(document).on('keyup', function(e) {
      if(e.keyCode === 32 && context.waitingForMinute) {    // space
        context.endAction();
        e.preventDefault();
      } else if(e.keyCode === 27) {    // esc
        context.reset();
        e.preventDefault();
      } else if(e.ctrlKey && e.keyCode == 90) {       // ctrl + z
        context.undo();
        e.preventDefault();
      }
    });
  },
  sortByTypeAndNumber: function(pid2data) {
    var arr = [];
    for(pid in pid2data) {
      arr.push( pid2data[pid] );
    }

    return arr.sort(function(a,b) {
      if(a.type == b.type) {
        if(a.number == '00') {
          return -1;
        } else if(b.number == '00') {
          return 1;
        } else {
          return a.number-b.number;
        }
      } else if(a.type == 'home') {
        return -1;
      } else {
        return 1;
      }
    });
  },
  factor: function(diff) {
    return diff < 0 ? -1 : 1;
  },
  field: function(diff) {
    var tmp = Math.abs(diff);
    if(tmp === 0) {
      return "mft";
    } else if(tmp === 1) {
      return "ft";
    } else if(tmp === 2) {
      return "pt2";
    } else if(tmp === 3) {
      return "pt3";
    } else {
      return null;
    }
  },
  player: function(pid) {
    return scoresheet.data.playerId2data[pid];
  },
  playerNameWithNumber: function(pid) {
    return scoresheet.player(pid).number + " (" + scoresheet.playerName(pid) + ")";
  },
  name: function(firstName, lastName, lastNameFirst, withInitials, isCaptain) {
    var first = firstName;
    if(withInitials) {
      var tmp = firstName.split(" ");
      var fn = [];
      for(var i = 0; i < tmp.length; i++) {
        if(tmp[i].length == 0) continue;
        fn.push( tmp[i].charAt(0) + "." );
      }
      first = fn.join(" ")
    }
    var k = isCaptain ? " (K)" : "";
    if(lastNameFirst) {
      return lastName.toUpperCase() + " " + first + k;
    } else {
      return first + " " + lastName.toUpperCase() + k;
    }
  },
  playerName: function(pid, lastNameFirst, withInitials) {
    var p = scoresheet.player(pid);
    return scoresheet.name(p.first_name, p.last_name, lastNameFirst, withInitials, context[pid].isCaptain);
  },
  playerType: function(pid) {
    return scoresheet.player(pid).type;
  },
  isOT: function(q) {
    return q > scoresheet.data.numberOfQuarters;
  },
  quarterName: function(q) {
    if(scoresheet.isOT(q)) {
      return (q - scoresheet.data.numberOfQuarters) + ". podaljšek";
    } else {
      return q + ". četrtina"; // TODO: quarter implies four parts
    }
  },
  saveTime: function() {
    $.ajax(scoresheet.data.timeUrl, {
      method: 'GET',
      success: function(data) {
        context.matchFinishedAt = data.timeString;
        scoresheet.save();
      }
    });
  },
  invite: function(email) {
    if(email) {
      $.ajax(scoresheet.data.inviteUrl, {
        method: 'GET',
        data: {
          email: email
        },
        timeout: 10000,
        success: function(data) {
          $('.invite-success-toast').toast('show');
        },
        error: function(err, status, msg) {
          console.error(status, msg, err);
          $('.invite-error-toast').toast('show');
        }
      });
    }
  },
  reportInvite: function() {
    var email = $('#share_report_email').val();
    if(email) {
      $.ajax(scoresheet.data.reportInviteUrl, {
        method: 'GET',
        data: {
          email: email
        },
        timeout: 10000,
        success: function(data) {
          $('.invite-success-toast').toast('show');
          $('#share_report_email').val("");
        },
        error: function(err, status, msg) {
          console.error(status, msg, err);
          $('.invite-error-toast').toast('show');
        }
      });
    }
  },
  localeStorageKey: function() {
    return `scoresheet-${scoresheet.data.matchId}`;
  },
  getFromLocalStorage: function() {
    return JSON.parse( window.localStorage.getItem(scoresheet.localeStorageKey()) || JSON.stringify({ data: {}, scoresheetObj: {} }) );
  },
  saveToLocalStorage: function(data, now) {
    now = now || Date.now();
    data.localStorageSaveTime = now;

    var obj = {
      data: scoresheet.data,
      scoresheetObj: data
    };

    window.localStorage.setItem(scoresheet.localeStorageKey(), JSON.stringify(obj));
  },
  save: function() {
    var timeout = 20000;
    var con = context;
  
    var scoresheetData = JSON.parse( JSON.stringify(con) );
    setTimeout(function() {
      // save to localstorage each time
      scoresheet.saveToLocalStorage(scoresheetData);
    }, 50);

    if(con.saving && con.startedSavingTime && con.startedSavingTime + timeout > Date.now()) {      
      con.saveOnSuccess = true;
      return;
    }
    con.saving = true;
    con.startedSavingTime = Date.now();
    
    // save to localstorage before ajax call and set serverSaveTime to now
    
    $.ajax(scoresheet.data.saveUrl, {
      method: 'PUT',
      data: {
        scoresheet: scoresheetData
      },
      timeout: timeout,
      success: function(data) {
        // save success time to localstorage and to context
        var now = Date.now();
        con.serverSaveTime = now;
        con.localStorageSaveTime = now;
        scoresheetData.serverSaveTime = now;
        scoresheet.saveToLocalStorage(scoresheetData, now);

        refreshContext();

        $('.success-toast').toast('show');
      },
      error: function(err, status, msg) {
        console.error(status, msg, err);
        $('.error-toast').toast('show');
      },
      complete: function() {
        con.saving = false;
        if(con.saveOnSuccess) {
          con.saveOnSuccess = false;
          scoresheet.save();
        }
      }
    });
  },
  updateColors: function(home, away) {
    $('.colorpicker[data-type=home][data-colortype=bg]').val(home.bg);
    $('.colorpicker[data-type=home][data-colortype=text]').val(home.text);
    $('.colorpicker[data-type=away][data-colortype=bg]').val(away.bg);
    $('.colorpicker[data-type=away][data-colortype=text]').val(away.text);

    var homeBgDark = adjust(home.bg, -20);
    var homeBgDarker = adjust(home.bg, -60);
    var awayBgDark = adjust(away.bg, -20);
    var awayBgDarker = adjust(away.bg, -60);

    var whites = ['#ffffff', 'white'];

    var homeBorderColor = whites.indexOf(home.bg) >= 0 ? 'black' : home.bg;
    var awayBorderColor = whites.indexOf(away.bg) >= 0 ? 'black' : away.bg;

    var homeSelectedColor = whites.indexOf(home.text) >= 0 ? 'black' : 'white';
    var homeSelectedBgColor = whites.indexOf(home.text) >= 0 ? 'white' : 'black';

    var awaySelectedColor = whites.indexOf(away.text) >= 0 ? 'black' : 'white';
    var awaySelectedBgColor = whites.indexOf(away.text) >= 0 ? 'white' : 'black';

    var homeText = whites.indexOf(home.text) >= 0 ? home.bg : home.text;
    var awayText = whites.indexOf(away.text) >= 0 ? away.bg : away.text;

    var $style = $('style#colors');
    var html = ".btn-home { color: "+home.text+"; background-color: "+home.bg+"; border-color: "+homeBorderColor+";  }";
    html += ".btn-home:hover { color: "+home.text+"; background-color: "+homeBgDark+"; border-color: "+homeBgDark+";  }";
    html += ".text-home { color: "+homeText+"}";
    html += ".home-entity.entity-selected, .home-entity.entity-selected:hover { color: "+homeSelectedColor+"; background-color: "+homeSelectedBgColor+"; border-color: black;  }";
    // html += ".entity-selected { color: black; background-color: white; border-color: black; }";
    html += ".home-jersey { color: "+home.text+"; fill: "+home.bg+";}"

    html += ".btn-away { color: "+away.text+"; background-color: "+away.bg+"; border-color: "+awayBorderColor+";  }";
    html += ".btn-away:hover { color: "+away.text+"; background-color: "+awayBgDark+"; border-color: "+awayBgDark+";  }";
    html += ".text-away { color: "+awayText+"}";
    html += ".away-entity.entity-selected, .away-entity.entity-selected:hover { color: "+awaySelectedColor+"; background-color: "+awaySelectedBgColor+"; border-color: black;  }";
    html += ".away-jersey { color: "+away.text+"; fill: "+away.bg+";}"

    $style.html(html);
  },
  
  buildEditSelects: function() {
    $('select.edit-select').find('option').remove();
    scoresheet.addOptionsToFilter(".edit-quarter-select", scoresheet.quarterOptions(true));
    scoresheet.addOptionsToFilter(".edit-minute-select", scoresheet.minuteOptions(true));
    scoresheet.addOptionsToFilter(".edit-type-select", scoresheet.typeOptions(true));
    scoresheet.addOptionsToFilter(".edit-home-players-select", scoresheet.playersOptions('home'));
    scoresheet.addOptionsToFilter(".edit-away-players-select", scoresheet.playersOptions('away'));
    scoresheet.addOptionsToFilter(".edit-change-score-23-select", scoresheet.changeScoreOptions([2,3]));
    scoresheet.addOptionsToFilter(".edit-change-ft-select", scoresheet.changeScoreOptions([0,1]));
    scoresheet.addOptionsToFilter(".edit-change-fouls-select", scoresheet.changeFoulsOptions());
    scoresheet.addOptionsToFilter(".edit-ft-type-select", scoresheet.ftTypesOptions());
  },
  buildNewSelects: function() {
    $('select.new-select').find('option').remove();
    scoresheet.addOptionsToFilter(".new-action-select", scoresheet.fnOptions());
    scoresheet.addOptionsToFilter(".new-quarter-select", scoresheet.quarterOptions());
    scoresheet.addOptionsToFilter(".new-minute-select", scoresheet.minuteOptions());
    scoresheet.addOptionsToFilter(".new-type-select", scoresheet.typeOptions());
    scoresheet.addOptionsToFilter(".new-home-players-select", scoresheet.playersOptions('home'));
    scoresheet.addOptionsToFilter(".new-away-players-select", scoresheet.playersOptions('away'));
    scoresheet.addOptionsToFilter(".new-change-score-23-select", scoresheet.changeScoreOptions([2,3]));
    scoresheet.addOptionsToFilter(".new-change-ft-select", scoresheet.changeScoreOptions([0,1]));
    scoresheet.addOptionsToFilter(".new-change-fouls-select", scoresheet.changeFoulsOptions());
    scoresheet.addOptionsToFilter(".new-ft-type-select", scoresheet.ftTypesOptions());
    scoresheet.addOptionsToFilter(".new-home-coach-and-bench-select", scoresheet.coachAndBenchOptions('home'));
    scoresheet.addOptionsToFilter(".new-away-coach-and-bench-select", scoresheet.coachAndBenchOptions('away'));
  },
  buildFilters: function() {
    $('select.filter').find('option').remove();
    scoresheet.refreshQuarterOptions();
    scoresheet.addOptionsToFilter("#minute-filter", scoresheet.minuteOptions());
    scoresheet.addOptionsToFilter("#state-filter", scoresheet.stateOptions());
    scoresheet.addOptionsToFilter("#type-filter", scoresheet.typeOptions());
    scoresheet.addOptionsToFilter("#number-filter", scoresheet.numberOptions());
    scoresheet.addOptionsToFilter("#entity-filter", scoresheet.entityOptions());
    scoresheet.addOptionsToFilter("#home-players-filter", scoresheet.playersOptions('home'));
    scoresheet.addOptionsToFilter("#away-players-filter", scoresheet.playersOptions('away'));
  },
  addOptionsToFilter: function(selector, options, defaultValue) {
    for(var i = 0; i < options.length; i++) {
      var $option = $('<option>').val(options[i].value).text(options[i].text);
      if(options[i].disabled) {
        $option.attr('disabled','disabled');
      }
      if(options[i].value == defaultValue) {
        $option.attr('selected','selected');
      }
      $(selector).append($option);
    }
  },
  refreshQuarterOptions: function() {
    $('select.filter#quarter-filter').find('option').remove();
    scoresheet.addOptionsToFilter("#quarter-filter", scoresheet.quarterOptions());
  },
  quarterOptions: function(isEdit) {
    var options = isEdit ? [] : [{ text: 'Vsi deli tekme' }];
    var n = context.quarter + (context.matchFinished ? -1 : 0);
    for(var i = 1; i <= n; i++) {
      options.push({ value: i, text: scoresheet.quarterName(i) });
    }
    return options;
  },
  minuteOptions: function(isEdit) {
    var options = isEdit ? [] : [{ text: 'Vse' }];
    var n = 10;
    for(var i = 1; i <= n; i++) {
      options.push({ value: i, text: i+"'" });
    }
    return options;
  },
  fnOptions: function() {
    return[
      { text: 'Vsi dogodki' },
      { text: "────────── METI ──────────", disabled: true },
      { value: "changeScore|2", text: "Koš za 2"},
      { value: "changeScore|3", text: "Koš za 3"},
      { value: "changeScore|1", text: "Zadet prosti met"},
      { value: "changeScore|0", text: "Zgrešen prosti met"},
      { value: "cancelFt", text: "Preklican prosti met"},
      { text: "──────── OS. NAPAKE ──────", disabled: true },
      { value: "changeFouls", text: "Os. napaka brez pr. metov"},
      { value: "changeFTFouls", text: "Os. napaka s pr. meti"},
      { value: "changeUFouls", text: "Nešp. napaka"},
      { value: "changeTFouls", text: "Teh. napaka"},
      { value: "changeEjections", text: "Izključitev"},
      { value: "changeFights", text: "Pretep"},
      { text: "────────── NAPAKE TRENERJA IN KLOPI ─────────", disabled: true },
      { value: "changeBenchTFouls", text: "Teh. napaka"},
      { value: "changeBenchEjections", text: "Izključitev"},
      { text: "────────── DRUGO ─────────", disabled: true },
      { value: "changeTimeouts", text: "Minuta odmora"},
      { value: "changeQuarter", text: "Konec četrtine"},
      { value: "finishMatch", text: "Konec tekme"},
      { value: "changePlayed", text: "Vstop v igro"}
    ];
  },
  stateOptions: function() {
    var options = [];
    var texts = [];
    for(action in scoresheet.data.logs.actions) {
      var name;
      if(action === 'changeScore') {
        for(var i = 0; i < 4; i++) {
          var text = scoresheet.data.logs.actions[action].name({diff: i});
          options.push({ value: text+"|"+i, text: text });
        }
      } else {
        var text = scoresheet.data.logs.actions[action].name();
        if(texts.indexOf(text) >= 0) continue;
        options.push({ value: text, text: text });
        texts.push(text);
      }
    }
    options.sort(objectSorter('text'));
    return [{ text: 'Vsi dogodki' }].concat(options);
  },
  changeScoreOptions: function(diffs) {
    var options = [];
    for(diff of diffs) {
      var text = scoresheet.data.logs.actions.changeScore.name({diff: diff});
      options.push( { value: diff, text: text } );
    }
    return options;
  },
  changeFoulsActions: function() {
    return ['changeFouls', 'changeFTFouls', 'changeTFouls', 'changeUFouls', 'changeEjections', 'changeFights'];
  },
  changeFoulsOptions: function() {
    var options = [];
    for(action of scoresheet.changeFoulsActions()) {
      var text = scoresheet.data.logs.actions[action].name();
      options.push( { value: action, text: text } );
    }
    return options;
  },
  typeOptions: function(isEdit) {
    var options = isEdit ? [] : [{ text: 'Obe ekipi' }];
    for(obj of [{ value: 'home', text: scoresheet.data.side2type2data.home.name }, { value: 'away', text: scoresheet.data.side2type2data.away.name }]) {
      options.push(obj);
    }
    return options;
  },
  numberOptions: function() {
    var options = [{ text: 'Vse št.' }];
    var numbers = ['00'];
    for(var i = 0; i < 100; i++) numbers.push(""+i);

    var pNumbers = [];
    for(pid in scoresheet.data.playerId2data) {
      var p = scoresheet.player(pid);
      pNumbers.push( p.number + "" );
    }

    for(var i = 0; i < numbers.length; i++) {
      var number = numbers[i];
      if(pNumbers.indexOf(number) >= 0) {
        options.push({ value: number, text: number });
      }
    }
    return options;
  },
  entityOptions: function() {
    var options = [{ text: 'Vsi akterji' }, { value: scoresheet.data.COACH, text: scoresheet.data.logs.coach }, { value: scoresheet.data.ASSISTANT, text: scoresheet.data.logs.assistant }, { value: scoresheet.data.OTHER, text: scoresheet.data.logs.other }];
    var poptions = [];
    for(pid in scoresheet.data.playerId2data) {
      poptions.push({ value: pid, text: scoresheet.playerName(pid, true, false) });
    }
    poptions.sort(objectSorter('text'));
    return options.concat(poptions);
  },
  playersOptions: function(type) {
    var options = [];
    var poptions = [];
    for(pid in scoresheet.data.playerId2data) {
      var data = scoresheet.data.playerId2data[pid];
      if(data.type === type) {
        poptions.push({ value: pid, text: data.number + " - " + scoresheet.playerName(pid, true, false) });
      }
    }
    poptions.sort(objectSorter(function(p) { var num = p.text.split(' - ')[0]; return parseInt(num); }));
    return options.concat(poptions);
  },
  coachAndBenchOptions: function(type) {
    return [{ text: 'Izberi trenerja ali klop' }, { value: scoresheet.data.COACH, text: scoresheet.data.logs.coach }, { value: scoresheet.data.ASSISTANT, text: scoresheet.data.logs.assistant }, { value: scoresheet.data.OTHER, text: scoresheet.data.logs.other }];
  },
  ftTypesOptions: function() {
    var options = [];
    for(type in scoresheet.data.ftType) {
      var obj = scoresheet.data.ftType[type];
      if(obj.key == "separator") {
        options.push({ text: obj.name, disabled: true })
      } else {
        options.push({ value: obj.key, text: obj.name });
      }

    }
    return options;
  }
}

global.objectSorter = function(property) {
  return function(p1,p2) {

    var fn = property;
    if(typeof(property) == 'string') {
      fn = function(p) { return p[property].toUpperCase(); }
    }

    var val1 = fn(p1); // ignore upper and lowercase
    var val2 = fn(p2); // ignore upper and lowercase
    if (val1 < val2) {
      return -1;
    } else if (val1 > val2) {
      return 1;
    } else {
      return 0; 
    }
  }
}; 

global.refreshContext = function() {
  Twine.refresh();
};

global.setData = function(key, val, obj) {
  var tmp = obj || context;
  var keys = key.split(".");
  var n = keys.length;
  for(var i = 0; i < n-1; i++) {
    if(!tmp[ keys[i] ]) tmp[ keys[i] ] = {};
    tmp = tmp[ keys[i] ];
  }
  tmp[ keys[n-1] ] = val;

};

global.setContext = function(key, val) {
  setData(key, val);
  refreshContext();
};

global.getData = function(key, obj) {
  var tmp = obj;
  var keys = key.split(".");
  var n = keys.length;
  for(var i = 0; i < n; i++) {
    if(!tmp) return null;
    tmp = tmp[ keys[i] ];
  }
  return tmp;
};

global.getContext = function(key) {
  return getData(key, context);
};

global.alerts = {
  defaults: {
    yesLabel: 'DA',
    noLabel: 'NE',
    continueLabel: 'NADALJUJ',
    type: 'warning'
  },
  queue: [],
  addToQueue: function(data) {
    alerts.queue.push(data);
    if(alerts.queue.length == 1) {
      alerts.executeFromQueue();
    }
  },
  executeFromQueue: function() {
    if(alerts.queue.length === 0) return;
    var data = alerts.queue[0];
    alerts.show(data);
  },
  info: function(title, body) {
    alerts.addToQueue({ title: title, body: body, type: 'info' });
  },
  warning: function(body) {
    alerts.addToQueue({ title: "POZOR!", body: body, type: "warning" });
  },
  success: function(title, body) {
    alerts.addToQueue({ title: title, body: body, type: "success", yesCallback: null, noCallback: null });
  },
  confirmation: function(data) {
    alerts.addToQueue(data);
  },
  // data = { title, body, type, yesCallback, noCallback, continueCallback, yesLabel, noLabel, continueLabel, onShowCallback }
  show: function(data) {
    data = $.extend({}, alerts.defaults, data);
    var id = "modal-" + new Date().getTime();

    var $modal = $($('#template-modal-holder').html());
    $modal.prop('id', id);

    var $title = $modal.find('.modal-header');
    $title.addClass('alert-'+data.type);
    $title.find('.modal-title').html(data.title);

    // prepare position
    var top = 0;
    var selector = '.modal:not(#template-modal)';
    var n = $(selector).length;
    for(var i = 0; i < n; i++) {
      top += $($(selector).get(i)).find('.modal-dialog').height();
    }

    var $body = $modal.find('.modal-body');
    $body.html(data.body);

    if(data.yesCallback || data.noCallback) {
      var yesSelector = "#modal-yes-btn";
      $modal.find(yesSelector).text(data.yesLabel);
      $modal.find(yesSelector).parent().removeClass('hide');
      $(document).on('click', '#' + id + " " + yesSelector, function() {
        if(data.yesCallback) {
          data.yesCallback();  
        }
        $modal.modal('hide');
      });

      var noSelector = "#modal-no-btn";
      $modal.find(noSelector).text(data.noLabel);
      $modal.find(noSelector).parent().removeClass('hide');
      $(document).on('click', '#' + id + " " + noSelector, function() {
        if(data.noCallback) {
          data.noCallback();  
        }
        $modal.modal('hide');
      });
    } else if(data.continueCallback) {
      var continueSelector = "#modal-continue-btn";
      $modal.find(continueSelector).text(data.continueLabel);
      $modal.find(continueSelector).parent().removeClass('hide');
      $(document).on('click', '#' + id + " " + continueSelector, function() {
        if(data.continueCallback) {
          data.continueCallback();  
        }
        $modal.modal('hide');
      });
    } else {
      $modal.find('.modal-footer').remove();
    }

    $modal.on('hidden.bs.modal', function (e) {
      $modal.remove();
    });

    $modal.on('shown.bs.modal', function (e) {
      if(data.onShowCallback) {
        data.onShowCallback();
      }
      alerts.queue.shift();
      alerts.executeFromQueue();
    });

    $modal.modal('show');

    $modal.css('padding-right', 0);
    $modal.css('top', top+"px");
  }
};

global.adjust = function(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

global.arraysEqual = function(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

global.preloadImages = function(imgs) {
  var images = new Array();
  for (i = 0; i < imgs.length; i++) {
    images[i] = new Image();
    images[i].src = imgs[i];
  }
};

global.signature = {
  data: {},
  init: function() {
    signature.data.canvas = document.querySelector("canvas");
    signature.data.pad = new SignaturePad( signature.data.canvas);
    window.onresize = signature.resizeCanvas;

    context = {
      showPad: function() { 
        return window.innerHeight < window.innerWidth;
      }
    };

    $('.submit-btn').click(signature.submit);
    $('.clear-btn').click(signature.clear);

    signature.resizeCanvas();
  },
  resizeCanvas: function() {
    refreshContext();
    setTimeout(function() {
      var w = $('#signature-pad').width();
      signature.setPadSize(w);
      signature.clear();
      signature.data.canvas.width = w;
      signature.data.canvas.height = window.innerHeight - $('#signature-pad-header').outerHeight() - $('#signature-pad-footer').outerHeight() - 5;
      window.scrollTo(0, 0);
    }, 300);
  },
  clear: function() {
    signature.data.pad.clear();
  },
  submit: function(e) {
    e.preventDefault();
    $('form input[name="base64_image"]').val(signature.data.pad.toDataURL());
    $('form').submit();
  },
  setPadSize: function(w) {
    var size = w/200 + 1; // set in the way that: 1800 -> 10, 600 -> 4
    signature.data.pad.minWidth = size;
    signature.data.pad.maxWidth = size;
  }
}

global.customMatch = {
    sl: {
      "format": "DD.MM.YYYY, HH:mm",
      "separator": " - ",
      "firstDay": 1
    },
    data: {},
    init: function(saveUrl) {
      customMatch.data.saveUrl = saveUrl;

      context = {
        json: function() {
          return JSON.stringify(this);
        },
        formatted_match_time: function() {
          return this.match_time_d ? this.match_time_d.format(customMatch.sl.format) : '';
        }
      };
      
      customMatch.initTimePicker();
    },
    initTimePicker: function() {
      var $item = $('.single-date-picker');
      var matchtime;
      var matchtimed = $item.data('matchtimed');
      if(matchtimed) {
        context.match_time_d = moment(matchtimed);
        context.date_time = context.match_time_d.format();
        matchtime = context.formatted_match_time();
        refreshContext();
      }
      $item.daterangepicker({
        locale: customMatch.sl,
        autoUpdateInput: !!matchtime,
        startDate: matchtime,
        timePicker: true,
        timePicker24Hour: true,
        timePickerSeconds: false,
        singleDatePicker: true
      });
      $item.on('apply.daterangepicker', function (ev, picker) {
        context.match_time_d = picker.startDate;
        context.date_time = context.match_time_d.format();
        refreshContext();
      });
    }
}

global.matchReport = {
  data: {},
  init: function(urls, matchId) {
    matchReport.data.urls = urls;
    matchReport.data.matchId = matchId;
    
    // define context
    context = $.extend(context, {
      home: {
        complaint: {},
        others: [],
        fouls: [],
        othersCount: 0
      },
      away: {
        complaint: {},
        others: [],
        fouls: [],
        othersCount: 0
      },
      reportJson: function() {
        return JSON.stringify(this);
      },
      isOfficialMissing: function(role) {
        return this.referees && this.referees[role] && this.referees[role].status === "missing";
      },
      officialName: function(role) {
        if(!this.referees || !this.referees[role] || this.isOfficialMissing(role)) {
          return "";
        } else {
          var s = this.referees[role].first_name + " " + this.referees[role].last_name;
          if(this.referees[role].city) {
            s += " (" + this.referees[role].city + ")";
          }
          return s;
        }
      },
      getObject: function(arr) {
        var obj = this;
        for(var key of arr) {
          obj = obj[key];
          if(!obj) break;
        }
        return obj;
      },
      shouldPersonSign: function(arr) {
        var obj = this.getObject(arr);
        return obj && obj.sig && !this.hasPersonSigned(arr);
      },
      hasPersonSigned: function(arr) {
        var obj = this.getObject(arr);
        return obj && obj.sig && obj.sig.status == 'SIGNED';
      },
      personSignedText: function(arr) {
        if(this.hasPersonSigned(arr)) {
          return "PODPISANO";
        } else {
          return "ČAKA NA PODPIS";
        }
      },
      canShowQrCode: function(arr) {
        var obj = this.getObject(arr);
        return obj && obj.sig;
      },
      showQrCode: function(arr) {
        if(this.canShowQrCode(arr)) {
          var person = this.getObject(arr);
          var uuid = person.sig.uuid;
          var url = matchReport.data.urls.signature.replace("XXidXX", uuid);
          alerts.show({ 
            title: person.first_name + " " + person.last_name, 
            body: "<p>Skeniraj QR kodo s telefonom in podpiši!</p><a href='"+url+"' target='_blank'><div class='signature-qr-code' id='"+uuid+"'></div></a>", 
            type: 'info', 
            onShowCallback: function() {
              new QRCode(document.getElementById(uuid), {
                text: url,
                width: 400,
                height: 400
              });
            }
          });
        }
      },
      confirmSigned: function(uuid) {
        for(d of [ ['referees', 'report'], ['referees', 'commissioner'], ['home', 'complaint', 'representative'], ['away', 'complaint', 'representative'] ]) {
          d.push('sig');
          var sig = this.getObject(d);
          if(sig && sig.uuid == uuid) {
            this.save();
            var $modal = $('.modal.show');
            if($modal.length > 0) {
              if($modal.find('#'+uuid).length > 0) {
                $modal.modal('hide');
              }
            }
            return;
          }
        }
      },
      complaintQuestionText: function(side) {
        if(!this[side] || !this[side].complaint || !this[side].complaint.captain) {
          return "";
        }
        if(side == 'home') {
          return "Ali kapetan domačih (" + this.home.complaint.teamName + ") vlaga pritožbo?";
        } else {
          return "Ali kapetan gostov (" + this.away.complaint.teamName + ") vlaga pritožbo?";
        }
      },
      captainName: function(side) {
        if(!this[side] || !this[side].complaint.captain) {
          return "";
        }
        return this[side].complaint.captain.first_name + " " + this[side].complaint.captain.last_name;
      },
      representativeLabel: function(side) {
        if(!this[side] || !this[side].complaint.representative) {
          return "";
        }
        var s = "Uradni predstavnik"
        if(this[side] && this[side].complaint.representative && this[side].complaint.representative.role == 'coach') {
          s += " (trener)"
        }
        return s + ":";
      },
      representativeName: function(side) {
        if(!this[side] || !this[side].complaint.representative) {
          return "";
        }
        return this[side].complaint.representative.first_name + " " + this[side].complaint.representative.last_name;
      },
      addOther: function(side) {
        this[side].othersCount++;
      },
      removeOther: function(side, i) {
        this[side].others.splice(i, 1);
        this[side].others.push({first_name: '', last_name: ''});
        this[side].othersCount--;
      },
      isOtherShown: function(side, i) {
        return this[side].othersCount > i;
      },
      foulName: function(foul) {
        var fn = foul['fn'];
        if(fn == 'changeTFouls' || fn == 'changeBenchTFouls') {
          return 'teh. napaka';
        } else if(fn == 'changeEjections' || fn == 'changeBenchEjections') {
          return 'izklj. napaka';
        } else {
          return "";
        }
      },
      foulRole: function(side, i) {
        if(this[side].fouls.length <= i) return undefined;
        var foul = this[side].fouls[i];
        return foul['pid'] ? 'PLAYER' : 'COACH';
      },
      foulRoleText: function(side, i) {
        if(this[side].fouls.length <= i) return undefined;
        return this.foulRole(side, i) == 'PLAYER' ? 'igralec' : 'trener';
      },
      foulText: function(side, i) {
        if(!this[side] || this[side].fouls.length <= i || !this[side].fouls[i]) return  "";
        var foul = this[side].fouls[i];
        return foul['first_name'] + " " + foul['last_name'].toUpperCase() + " (" + this.foulRoleText(side, i) + ") - " + this.foulName(foul);
      },
      save: function(successCallback) {
        matchReport.clearSave();
        if(context.saving) {
          context.saveOnSuccess = true;
          return;
        }
        context.saving = true;
        var con = context;
        $.ajax(matchReport.data.urls.save, {
          method: 'PUT',
          data: {
            "match[report]": con.reportJson()
          },
          success: function(data) {
            $.extend(context, data.report);
            con.saving = false;
            if(con.saveOnSuccess) {
              con.saveOnSuccess = false;
              con.save();
            }
            refreshContext();
            if(successCallback) {
              successCallback();
            }
            matchReport.setSave();
          },
          complete: function() {
            con.saving = false;
          }
        });
      }
    });

    // get report from server
    $.ajax(matchReport.data.urls.save, {
      success: function(data) {
        matchReport.initAfterLoad(data.report);
        window.localStorage.removeItem(`scoresheet-${matchReport.data.matchId}`);
        setContext("loaded", true);
        refreshContext();
      }
    });

  },
  clearSave: function() {
    if(matchReport.data.saveTimeout) {
      clearTimeout(matchReport.data.saveTimeout);
    }
  },
  setSave: function() {
    matchReport.data.saveTimeout = setTimeout(context.save, 20*1000);
  },
  initAfterLoad: function(report) {
    context = $.extend(context, report, {saving: false});
    matchReport.setSave();
  }
};

global.followMatch = {
  data: {},
  init: function(matchId) {
    followMatch.data.matchId = matchId;
    ws.subscribe("FollowChannel", { matchId: matchId }, followMatch.refresh);
    context = {
      staffText: function(type) {
        return scoresheet.data.logs[type];
      },
      teamName: function(type) {
        return scoresheet.data.logs[type];
      },
      logActionText: function(log) {
        return scoresheet.data.logs.actions[log.fn].name(log);
      }
    };
    Twine.reset(context).bind().refresh();
  },
  refresh: function(data) {
    if(data.html) {
      $('#follow-pdf').html(data.html);
      $('#follow-logs').html(data.playByPlayHtml);
      Twine.reset(context).bind().refresh();
    }
  }
}