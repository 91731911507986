import * as ActionCable from '@rails/actioncable'

ActionCable.logger.enabled = true

import consumer from "./consumer"

consumer.subscriptions.create("SignatureChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("signature channel connected")
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log("signature channel disconnected")
  },

  received(data) {
    if(context) {
      context.confirmSigned(data.uuid);
    }
  }
});
